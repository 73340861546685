import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { useRecoilValue } from 'recoil'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import WebsiteIcon from '@mui/icons-material/Language'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import AddressIcon from '@mui/icons-material/LocationOn'

import {
  MainHeader,
  StrongText,
  SubContentWrapper,
  SubHeader,
} from 'components/StyledComponents'
import EmptyItemIcon from 'assets/icons/empty_item.svg'
import useRoute from 'hooks/useNavigate'
import { Path } from '../commonConstants'
import { portalSettingState } from 'state/portalSettingStates'
import LogoWrapper from 'components/LogoWrapper'
import usePortalSetting from 'hooks/usePortalSetting'
import CommunityIcon from 'assets/icons/community.svg'
import CommunityPrivateIcon from 'assets/icons/community_private.svg'
import OpenModeIcon from 'assets/icons/open_mode.svg'
import OpenModePrivateIcon from 'assets/icons/open_mode_private.svg'
import {
  ContactDetailType,
  ItemPrivacyMode,
} from 'components/item/itemConstants'
import { type Item } from 'components/item/itemTypes'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'
import Divider from '@mui/material/Divider'

const ItemWrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.divider};
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};

    .sub-content {
      background-color: ${({ theme }) => theme.palette.background.paper};
    }
  }
`

const PrivacyIconWrapper = styled(Stack)`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  padding: 6px 6px;
  background: ${({ theme }) => theme.palette.primary.main};
  border-top-left-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border-bottom-right-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  align-items: center;
`

const ItemListPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const portalSetting = useRecoilValue(portalSettingState)
  const { getLocalizedContent, formatPhoneNumber } = usePortalSetting()
  const { data: itemsData, isLoading } = useSWR<Item[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/items`
      : null,
  )
  const { goTo } = useRoute()
  const { checkAccesses } = useMember()

  const handleClick = (itemId: string): void => {
    goTo(`${Path.ITEMS_EDIT}/${itemId}`)
  }

  const sortedItems = useMemo(
    () =>
      itemsData?.sort((a, b) => {
        const nameA = getLocalizedContent(a.names).toUpperCase()
        const nameB = getLocalizedContent(b.names).toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        return 0
      }),
    [itemsData],
  )

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>
            {formatMessage({ id: 'portal_item_list.header' })}
          </MainHeader>
        </Box>

        {sortedItems &&
          sortedItems?.length > 0 &&
          checkAccesses({ [PortalSection.ITEMS]: [FeatureAccess.WRITE] }) && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                goTo(Path.ITEMS_ADD)
              }}
              color="secondary"
            >
              {formatMessage({ id: 'portal_item_list.button.create_item' })}
            </Button>
          )}
      </Stack>

      <Stack flexGrow={1} overflow="auto" spacing={2}>
        {!sortedItems ||
          (sortedItems.length === 0 && (
            <ItemWrapper
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              {isLoading && <CircularProgress />}

              {!isLoading &&
                checkAccesses({
                  [PortalSection.ITEMS]: [FeatureAccess.WRITE],
                }) && (
                  <Stack textAlign="center" spacing={2} alignItems="center">
                    <EmptyItemIcon />
                    <Box width="100%">
                      <SubHeader>
                        {formatMessage({
                          id: 'portal_item_list.label.start_creating_item',
                        })}
                      </SubHeader>
                    </Box>
                    <Box width="100%">
                      <Button
                        variant="contained"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => {
                          goTo(Path.ITEMS_ADD)
                        }}
                        color="secondary"
                      >
                        {formatMessage({
                          id: 'portal_item_list.button.create_item',
                        })}
                      </Button>
                    </Box>
                  </Stack>
                )}
            </ItemWrapper>
          ))}

        {sortedItems?.map((item) => (
          <ItemWrapper
            key={item.id}
            direction="row"
            spacing={2}
            paddingX={4}
            paddingY={2}
            alignItems="center"
            onClick={() => {
              if (
                checkAccesses({
                  [PortalSection.ITEMS]: [FeatureAccess.WRITE],
                })
              ) {
                handleClick(item.id)
              }
            }}
          >
            <PrivacyIconWrapper>
              {item.privacyMode ===
                ItemPrivacyMode.COMMUNITY_MODE_PRIVATE_REPORTING && (
                <CommunityPrivateIcon />
              )}
              {item.privacyMode === ItemPrivacyMode.COMMUNITY_MODE && (
                <CommunityIcon />
              )}
              {item.privacyMode ===
                ItemPrivacyMode.OPEN_MODE_PRIVATE_REPORTING && (
                <OpenModePrivateIcon />
              )}
              {item.privacyMode === ItemPrivacyMode.OPEN_MODE && (
                <OpenModeIcon />
              )}
            </PrivacyIconWrapper>
            <LogoWrapper
              size={120}
              url={item.logoUrl}
              alt={getLocalizedContent(item.names)}
            >
              {!item.logoUrl && (
                <LightbulbIcon fontSize="small" sx={{ fontSize: 80 }} />
              )}
            </LogoWrapper>

            <Stack justifyContent="center" spacing={1} flex={1} minWidth={300}>
              <SubHeader>{getLocalizedContent(item.names)}</SubHeader>
              <StrongText>{item.website}</StrongText>

              {(item.address || item.locationDetails?.location?.address) && (
                <StrongText>
                  <address>
                    {item.address || item.locationDetails?.location?.address}
                  </address>
                </StrongText>
              )}
            </Stack>

            <Stack flex={1.5} spacing={1} minWidth={300}>
              {portalSetting &&
                item.contacts.map((contact, index) => (
                  <SubContentWrapper key={index} direction="row" spacing={1}>
                    <Stack spacing={1} flex={1}>
                      <StrongText>
                        {getLocalizedContent(contact.names)}
                      </StrongText>
                      <Typography
                        variant="body2"
                        sx={{ whiteSpace: 'pre-line' }}
                      >
                        {getLocalizedContent(contact.descriptions)}
                      </Typography>
                    </Stack>

                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Stack flex={1} spacing={1}>
                      {contact.details.map((info, index) => (
                        <Stack
                          key={index}
                          direction="row"
                          spacing={1}
                          sx={{ whiteSpace: 'pre-line' }}
                        >
                          {info.type === ContactDetailType.PHONE && (
                            <PhoneIcon fontSize="small" />
                          )}
                          {info.type === ContactDetailType.EMAIL && (
                            <EmailIcon fontSize="small" />
                          )}
                          {info.type === ContactDetailType.WEBSITE && (
                            <WebsiteIcon fontSize="small" />
                          )}
                          <Typography variant="body2">
                            {info.type === ContactDetailType.PHONE
                              ? formatPhoneNumber(
                                  getLocalizedContent(info.texts),
                                )
                              : getLocalizedContent(info.texts)}
                          </Typography>
                        </Stack>
                      ))}

                      {!!contact.addresses && contact.addresses.length > 0 && (
                        <Stack direction="row" spacing={1}>
                          <AddressIcon fontSize="small" />
                          <address>
                            <Typography variant="body2">
                              {getLocalizedContent(contact.addresses)}
                            </Typography>
                          </address>
                        </Stack>
                      )}
                    </Stack>
                  </SubContentWrapper>
                ))}
            </Stack>
          </ItemWrapper>
        ))}
      </Stack>
    </Stack>
  )
}

export default ItemListPage
