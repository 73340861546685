import EmailIcon from '@mui/icons-material/Email'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import DomainIcon from '@mui/icons-material/Domain'
import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore'
import StairsIcon from '@mui/icons-material/Stairs'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import PinIcon from '@mui/icons-material/Pin'
import BadgeIcon from '@mui/icons-material/Badge'

import { isValidEmail, isValidPhoneNumber } from 'utils/stringUtils'
import {
  type CustomerProfileFormField,
  type CustomerProfileSettingField,
  type Locale,
} from './types'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import { type PortalSectionAccesses } from 'components/role/roleTypes'

export const LOCALES = [
  'AR',
  'BE',
  'BG',
  'CS',
  'DA',
  'DE',
  'EN',
  'ES',
  'ET',
  'FI',
  'FR',
  'GA',
  'HE',
  'HI',
  'HR',
  'HU',
  'HY',
  'ID',
  'IS',
  'IT',
  'JA',
  'KA',
  'KO',
  'KU',
  'LA',
  'LT',
  'LV',
  'MK',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'RU',
  'SK',
  'SL',
  'SQ',
  'SR',
  'SV',
  'TH',
  'TL',
  'TR',
  'UK',
  'VI',
  'ZH',
]

export const DEFAULT_LOCALE: Locale = 'EN'
export const SUPPORT_LOCALES: Locale[] = [
  'EN',
  'FI',
  'NO',
  'SV',
  'TR',
  'DE',
  'LT',
  'PL',
]
export const SUPPORT_LOCALE_LABEL: Partial<Record<Locale, string>> = {
  EN: 'English',
  FI: 'Suomi',
  NO: 'Norsk',
  SV: 'Svenska',
  TR: 'Türkçe',
  DE: 'Deutsch',
  LT: 'Lietuvių',
  PL: 'Polski',
}
export const SUPPORT_REGIONS = [
  'AT',
  'DE',
  'FI',
  'LT',
  'NO',
  'PL',
  'SE',
  'TR',
] as const
export const SUPPORT_REGION_LABEL: Record<
  (typeof SUPPORT_REGIONS)[number],
  string
> = {
  AT: 'map_region.austria',
  DE: 'map_region.germany',
  FI: 'map_region.finland',
  LT: 'map_region.lithuania',
  NO: 'map_region.norway',
  PL: 'map_region.poland',
  SE: 'map_region.sweden',
  TR: 'map_region.turkey',
}
export const PREPORTI_SUPPORT_LOCALES_MAP: Record<Locale, string> = {
  FI: 'fi/',
  SV: 'sv/',
}
export const SUPPORT_REGIONS_PHONE_PREFIX_MAP: Record<string, string> = {
  FI: '+358',
  SE: '+46',
  NO: '+47',
  TR: '+90',
  LT: '+370',
  DE: '+49',
  AT: '+43',
  PL: '+48',
}

export const MIN_PASSWORD_LENGTH = 8
export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy HH:mm'
export const MAP_VIEW_MAX_MARKERS = 1000
export const SMALL_SCREEN_SIZE = 1300
export const MOBILE_SCREEN_SIZE = 1000
export const USER_NAME_MAX_LENGTH = 50
export const EMAIL_MAX_LENGTH = 100
export const PHONE_MAX_LENGTH = 20
export const ADDRESS_MAX_LENGTH = 100
export const SHORT_TEXT_MAX_LENGTH = 100
export const PORTAL_NAME_MAX_LENGTH = 50
export const BUSINESS_ID_MAX_LENGTH = 20

export const TASK_DESCRIPTION_MAX_LENGTH = 2000
export const FILE_DESCRIPTION_MAX_LENGTH = 500
export const COMMENT_MAX_LENGTH = 2000
export const COMMENT_ATTACHMENTS_MAX_COUNT = 5

export const CATEGORY_NAME_MAX_LENGTH = 50
export const CATEGORY_MAX_DEPTH = 3

export const ROLE_NAME_MAX_LENGTH = 50

export const TEAM_NAME_MAX_LENGTH = 50
export const TEAM_DESCRIPTION_MAX_LENGTH = 1000

export const EMERGENCY_PHONE_NUMBERS = ['112']

export const ALERT_TEXT_MAX_LENGTH = 300

export const SUPPORT_DATE_FORMATS = [
  'dd.MM.yyyy HH:mm',
  'dd/MM/yyyy HH:mm',
  'dd-MM-yyyy HH:mm',
  'yyyy.MM.dd HH:mm',
  'yyyy-MM-dd HH:mm',
]

export enum ThemeMode {
  LIGHT = 'light',
  DARK = 'dark',
}
export enum FileType {
  IMAGE = 'IMAGE',
  DOC = 'DOC',
  PDF = 'PDF',
  EMAIL = 'EMAIL',
  OTHER = 'OTHER',
}

export enum PortalType {
  CITY = 'CITY',
  PROPERTY = 'PROPERTY',
}

export enum CustomerProfileInputType {
  REQUIRED = 'required',
  OPTIONAL = 'optional',
  HIDDEN = 'hidden',
}

export enum CustomerProfileInputName {
  NAME = 'name',
  PHONE = 'phone',
  EMAIL = 'email',
  ADDRESS = 'address',
  IDENTIFIER = 'identifier',
  COMPANY = 'company',
  BUILDING = 'building',
  SECTION = 'section',
  FLOOR = 'floor',
  APARTMENT = 'unitApartment',
}

export enum MenuMode {
  OPENED = 'OPENED',
  CLOSED = 'CLOSED',
}

export const CUSTOMER_PROFILE_FIELDS: CustomerProfileSettingField[] = [
  {
    name: CustomerProfileInputName.NAME,
    label: 'portal_item_edit.step.customer_profile.label.full_name',
    value: CustomerProfileInputType.REQUIRED,
    disabled: true,
  },
  {
    name: CustomerProfileInputName.PHONE,
    label: 'portal_item_edit.step.customer_profile.label.phone',
    value: CustomerProfileInputType.OPTIONAL,
    disabled: false,
  },
  {
    name: CustomerProfileInputName.EMAIL,
    label: 'portal_item_edit.step.customer_profile.label.email',
    value: CustomerProfileInputType.REQUIRED,
    disabled: true,
  },
  {
    name: CustomerProfileInputName.ADDRESS,
    label: 'portal_item_edit.step.customer_profile.label.address',
    value: CustomerProfileInputType.OPTIONAL,
    disabled: false,
  },
  {
    name: CustomerProfileInputName.IDENTIFIER,
    label: 'portal_item_edit.step.customer_profile.label.customer_id',
    value: CustomerProfileInputType.OPTIONAL,
    disabled: false,
  },
  {
    name: CustomerProfileInputName.COMPANY,
    label: 'portal_item_edit.step.customer_profile.label.company',
    value: CustomerProfileInputType.HIDDEN,
    disabled: false,
  },
  {
    name: CustomerProfileInputName.BUILDING,
    label: 'portal_item_edit.step.customer_profile.label.building',
    value: CustomerProfileInputType.HIDDEN,
    disabled: false,
  },
  {
    name: CustomerProfileInputName.SECTION,
    label: 'portal_item_edit.step.customer_profile.label.section',
    value: CustomerProfileInputType.HIDDEN,
    disabled: false,
  },
  {
    name: CustomerProfileInputName.FLOOR,
    label: 'portal_item_edit.step.customer_profile.label.floor',
    value: CustomerProfileInputType.HIDDEN,
    disabled: false,
  },
  {
    name: CustomerProfileInputName.APARTMENT,
    label: 'portal_item_edit.step.customer_profile.label.room',
    value: CustomerProfileInputType.OPTIONAL,
    disabled: false,
  },
]

export const CUSTOMER_PROFILE_FORM_FIELDS: CustomerProfileFormField[] = [
  {
    name: CustomerProfileInputName.PHONE,
    label: 'portal_item_edit.step.customer_profile.label.phone',
    maxLength: PHONE_MAX_LENGTH,
    validate: (val): boolean => {
      if (!val) {
        return true
      }

      return isValidPhoneNumber(val)
    },
    icon: LocalPhoneIcon,
  },
  {
    name: CustomerProfileInputName.EMAIL,
    label: 'portal_item_edit.step.customer_profile.label.email',
    maxLength: EMAIL_MAX_LENGTH,
    validate: (val): boolean => {
      if (!val) {
        return true
      }

      return isValidEmail(val)
    },
    icon: EmailIcon,
  },
  {
    name: CustomerProfileInputName.ADDRESS,
    label: 'portal_item_edit.step.customer_profile.label.address',
    maxLength: ADDRESS_MAX_LENGTH,
    icon: LocationOnIcon,
  },
  {
    name: CustomerProfileInputName.BUILDING,
    label: 'portal_item_edit.step.customer_profile.label.building',
    maxLength: ADDRESS_MAX_LENGTH,
    icon: DomainIcon,
  },
  {
    name: CustomerProfileInputName.SECTION,
    label: 'portal_item_edit.step.customer_profile.label.section',
    maxLength: ADDRESS_MAX_LENGTH,
    icon: LocalConvenienceStoreIcon,
  },
  {
    name: CustomerProfileInputName.FLOOR,
    label: 'portal_item_edit.step.customer_profile.label.floor',
    maxLength: ADDRESS_MAX_LENGTH,
    icon: StairsIcon,
  },
  {
    name: CustomerProfileInputName.APARTMENT,
    label: 'portal_item_edit.step.customer_profile.label.room',
    maxLength: ADDRESS_MAX_LENGTH,
    icon: MeetingRoomIcon,
  },
  {
    name: CustomerProfileInputName.IDENTIFIER,
    label: 'portal_item_edit.step.customer_profile.label.customer_id',
    maxLength: ADDRESS_MAX_LENGTH,
    icon: PinIcon,
  },
  {
    name: CustomerProfileInputName.COMPANY,
    label: 'portal_item_edit.step.customer_profile.label.company',
    maxLength: ADDRESS_MAX_LENGTH,
    icon: BadgeIcon,
  },
]

export enum PromiseStatus {
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export enum VideoCallStatus {
  DECLINED = 'declined',
  DROPPED = 'dropped',
}

export enum TaskEditingMode {
  ADDING = 'ADDING',
  COPYING = 'COPYING',
  EDITING = 'EDITING',
  ASSIGNING = 'ASSIGNING',
}

export enum AUTH_ERROR {
  INVALID_PASSWORD = 'auth/wrong-password',
  EMAIL_NOT_FOUND = 'auth/user-not-found',
  TOO_MANY_REQUESTS = 'auth/too-many-requests',
  INVALID_EMAIL = 'auth/invalid-email',
  INVALID_PHONE = 'auth/invalid-phone-number',
  NO_PORTAL = 'no-portal',
  NO_USER = 'no-user',
}

export const AUTH_ERROR_MESSAGE: Record<AUTH_ERROR, string> = {
  [AUTH_ERROR.INVALID_PASSWORD]: 'auth.error.wrong_password',
  [AUTH_ERROR.INVALID_EMAIL]: 'general.error.validate_email',
  [AUTH_ERROR.INVALID_PHONE]: 'general.error.validate_phone',
  [AUTH_ERROR.EMAIL_NOT_FOUND]: 'auth.error.email_not_exist',
  [AUTH_ERROR.TOO_MANY_REQUESTS]: 'auth.error.too_many_requests',
  [AUTH_ERROR.NO_PORTAL]: 'auth.error.no_portal',
  [AUTH_ERROR.NO_USER]: 'auth.error.no_user',
}

export enum Path {
  ROOT = '/',
  LOGIN = '/login',
  LOGOUT = '/logout',
  AUTH = '/auth',
  FORGOT_PASSWORD = '/forgotPassword',
  SIGN_UP = '/signup',
  PUBLIC_PAGE = '/public',
  PUBLIC_CASE_LIST = '/public/cases',
  PUBLIC_CASE_ADD = '/public/casesAdd',
  PROFILE = '/profile',
  ALERTS_LIST = '/alerts',
  CASES_LIST = '/cases',
  CASES_ADD = '/casesAdd',
  CASES_EDIT = '/casesEdit',
  MEMBERS_LIST = '/members',
  MEMBERS_ADD = '/membersAdd',
  MEMBERS_EDIT = '/membersEdit',
  TEAMS_LIST = '/teams',
  TEAMS_ADD = '/teamsAdd',
  TEAMS_EDIT = '/teamsEdit',
  CUSTOMERS_LIST = '/customers',
  CUSTOMERS_ADD = '/customersAdd',
  CUSTOMERS_EDIT = '/customersEdit',
  ITEMS_LIST = '/items',
  ITEMS_ADD = '/itemsAdd',
  ITEMS_EDIT = '/itemsEdit',
  ROLES_LIST = '/roles',
  ROLES_EDIT = '/rolesEdit',
  PORTALS_LIST = '/portals',
  PORTALS_ADD = '/portalsAdd',
  PORTALS_EDIT = '/portalsEdit',
  CATEGORIES_LIST = '/categories',
  CATEGORIES_EDIT = '/categoriesEdit',
  CATEGORIES_EDITOR = '/categoriesEditor',
  CONTACTS_LIST = '/contacts',
  CONTACTS_ADD = '/contactsAdd',
  CONTACTS_EDIT = '/contactsEdit',
  POLLS_LIST = '/polls',
  POLLS_ADD = '/pollsAdd',
  POLLS_EDIT = '/pollsEdit',
  NEWS_LIST = '/news',
  NEWS_ADD = '/newsAdd',
  NEWS_EDIT = '/newsEdit',
  COMMENT_TEMPLATES_LIST = '/commentTemplates',
  COMMENT_TEMPLATES_ADD = '/commentTemplatesAdd',
  COMMENT_TEMPLATES_EDIT = '/commentTemplatesEdit',
  TASK_TEMPLATES_LIST = '/taskTemplates',
  TASK_TEMPLATES_ADD = '/taskTemplatesAdd',
  TASK_TEMPLATES_EDIT = '/taskTemplatesEdit',
  WORKFLOWS_LIST = '/workflows',
  WORKFLOWS_ADD = '/workflowsAdd',
  WORKFLOWS_EDIT = '/workflowsEdit',
  OFFERS_LIST = '/offers',
  OFFERS_ADD = '/offersAdd',
  OFFERS_EDIT = '/offersEdit',
  STATISTICS = '/statistics',
  VIDEO_CALL_CASE = '/videoCallCase',
  VIDEO_CALL_TASK = '/videoCallTask',
  UNAUTHORIZED = '/unauthorized',
}

export const PATH_ACCESSES: Partial<Record<Path, PortalSectionAccesses>> = {
  [Path.ITEMS_LIST]: {
    [PortalSection.ITEMS]: [FeatureAccess.READ, FeatureAccess.WRITE],
  },
  [Path.ITEMS_ADD]: {
    [PortalSection.ITEMS]: [FeatureAccess.WRITE],
  },
  [Path.ITEMS_EDIT]: {
    [PortalSection.ITEMS]: [FeatureAccess.WRITE],
  },
  [Path.MEMBERS_LIST]: {
    [PortalSection.MEMBERS]: [FeatureAccess.READ, FeatureAccess.WRITE],
  },
  [Path.MEMBERS_ADD]: {
    [PortalSection.MEMBERS]: [FeatureAccess.WRITE],
  },
  [Path.MEMBERS_EDIT]: {
    [PortalSection.MEMBERS]: [FeatureAccess.WRITE],
  },
  [Path.TEAMS_LIST]: {
    [PortalSection.TEAMS]: [FeatureAccess.READ, FeatureAccess.WRITE],
  },
  [Path.TEAMS_ADD]: {
    [PortalSection.TEAMS]: [FeatureAccess.WRITE],
  },
  [Path.TEAMS_EDIT]: {
    [PortalSection.TEAMS]: [FeatureAccess.WRITE],
  },
  [Path.CUSTOMERS_LIST]: {
    [PortalSection.CUSTOMERS]: [FeatureAccess.READ, FeatureAccess.WRITE],
  },
  [Path.CUSTOMERS_ADD]: {
    [PortalSection.CUSTOMERS]: [FeatureAccess.WRITE],
  },
  [Path.CUSTOMERS_EDIT]: {
    [PortalSection.CUSTOMERS]: [FeatureAccess.WRITE],
  },
  [Path.ALERTS_LIST]: {
    [PortalSection.ALERTS]: [FeatureAccess.READ, FeatureAccess.WRITE],
  },
  [Path.CONTACTS_LIST]: {
    [PortalSection.CONTACTS]: [FeatureAccess.READ, FeatureAccess.WRITE],
  },
  [Path.CONTACTS_ADD]: {
    [PortalSection.CONTACTS]: [FeatureAccess.WRITE],
  },
  [Path.CONTACTS_EDIT]: {
    [PortalSection.CONTACTS]: [FeatureAccess.WRITE],
  },
  [Path.COMMENT_TEMPLATES_LIST]: {
    [PortalSection.COMMENT_TEMPLATES]: [
      FeatureAccess.READ,
      FeatureAccess.WRITE,
    ],
  },
  [Path.COMMENT_TEMPLATES_ADD]: {
    [PortalSection.COMMENT_TEMPLATES]: [FeatureAccess.WRITE],
  },
  [Path.COMMENT_TEMPLATES_EDIT]: {
    [PortalSection.COMMENT_TEMPLATES]: [FeatureAccess.WRITE],
  },
  [Path.TASK_TEMPLATES_LIST]: {
    [PortalSection.TASK_TEMPLATES]: [FeatureAccess.READ, FeatureAccess.WRITE],
  },
  [Path.TASK_TEMPLATES_ADD]: {
    [PortalSection.TASK_TEMPLATES]: [FeatureAccess.WRITE],
  },
  [Path.TASK_TEMPLATES_EDIT]: {
    [PortalSection.TASK_TEMPLATES]: [FeatureAccess.WRITE],
  },
  [Path.ROLES_LIST]: {
    [PortalSection.ROLES]: [FeatureAccess.READ, FeatureAccess.WRITE],
  },
  [Path.ROLES_EDIT]: {
    [PortalSection.ROLES]: [FeatureAccess.WRITE],
  },
  [Path.CATEGORIES_LIST]: {
    [PortalSection.CATEGORIES]: [FeatureAccess.READ, FeatureAccess.WRITE],
  },
  [Path.CATEGORIES_EDIT]: {
    [PortalSection.CATEGORIES]: [FeatureAccess.WRITE],
  },
  [Path.CATEGORIES_EDITOR]: {
    [PortalSection.CATEGORIES]: [FeatureAccess.WRITE],
  },
  [Path.PORTALS_EDIT]: {
    [PortalSection.SETTINGS]: [FeatureAccess.READ, FeatureAccess.WRITE],
  },
  [Path.CASES_LIST]: {
    [PortalSection.CASES]: [FeatureAccess.READ, FeatureAccess.WRITE],
  },
  [Path.CASES_ADD]: {
    [PortalSection.CASES]: [FeatureAccess.READ, FeatureAccess.WRITE],
  },
  [Path.CASES_EDIT]: {
    [PortalSection.CASES]: [FeatureAccess.READ, FeatureAccess.WRITE],
  },
  [Path.POLLS_LIST]: {
    [PortalSection.POLLS]: [FeatureAccess.READ, FeatureAccess.WRITE],
  },
  [Path.POLLS_ADD]: {
    [PortalSection.POLLS]: [FeatureAccess.WRITE],
  },
  [Path.POLLS_EDIT]: {
    [PortalSection.POLLS]: [FeatureAccess.WRITE],
  },
  [Path.VIDEO_CALL_CASE]: {
    [PortalSection.CASES]: [FeatureAccess.READ, FeatureAccess.WRITE],
  },
  [Path.NEWS_LIST]: {
    [PortalSection.NEWS_POSTS]: [FeatureAccess.READ, FeatureAccess.WRITE],
  },
  [Path.NEWS_ADD]: {
    [PortalSection.NEWS_POSTS]: [FeatureAccess.WRITE],
  },
  [Path.NEWS_EDIT]: {
    [PortalSection.NEWS_POSTS]: [FeatureAccess.WRITE],
  },
  [Path.WORKFLOWS_LIST]: {
    [PortalSection.WORKFLOWS]: [FeatureAccess.READ, FeatureAccess.WRITE],
  },
  [Path.WORKFLOWS_ADD]: {
    [PortalSection.WORKFLOWS]: [FeatureAccess.WRITE],
  },
  [Path.WORKFLOWS_EDIT]: {
    [PortalSection.WORKFLOWS]: [FeatureAccess.WRITE],
  },
  [Path.OFFERS_LIST]: {
    [PortalSection.OFFERS]: [FeatureAccess.READ, FeatureAccess.WRITE],
  },
  [Path.OFFERS_ADD]: {
    [PortalSection.OFFERS]: [FeatureAccess.WRITE],
  },
  [Path.OFFERS_EDIT]: {
    [PortalSection.OFFERS]: [FeatureAccess.WRITE],
  },
  [Path.STATISTICS]: {
    [PortalSection.STATS]: [FeatureAccess.READ],
  },
}
