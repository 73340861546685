import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import dayjs, { type Dayjs } from 'dayjs'
import isBetweenPlugin from 'dayjs/plugin/isBetween'
import { styled } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import {
  TextField,
  Popover,
  Box,
  InputAdornment,
  type TextFieldVariants,
  Stack,
  Divider,
  Button,
} from '@mui/material'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import {
  PickersDay,
  type PickersDayProps,
} from '@mui/x-date-pickers/PickersDay'
import CalendarIcon from '@mui/icons-material/Event'

dayjs.extend(isBetweenPlugin)

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  isSelected: boolean
  isHovered: boolean
  isStartDay: boolean
  isEndDay: boolean
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomPickerDayProps>(
  ({ theme, isSelected, isHovered, isStartDay, isEndDay }) => ({
    borderRadius: 0,
    ...(isSelected && {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    }),
    ...(isHovered && {
      backgroundColor: theme.palette.primary.light,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.light,
      },
      ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.primary.dark,
        '&:hover, &:focus': {
          backgroundColor: theme.palette.primary.dark,
        },
      }),
    }),
    ...(isStartDay && {
      borderTopLeftRadius: '45%',
      borderBottomLeftRadius: '45%',
    }),
    ...(isEndDay && {
      borderTopRightRadius: '45%',
      borderBottomRightRadius: '45%',
    }),
  }),
) as React.ComponentType<CustomPickerDayProps>

type CustomDayProps = PickersDayProps<Dayjs> & {
  startDay?: Dayjs | null
  endDay?: Dayjs | null
  hoveredDay?: Dayjs | null
}

const Day: React.FC<CustomDayProps> = (props) => {
  const { day, startDay, endDay, hoveredDay, ...other } = props

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={
        !!startDay && !!endDay && day.isBetween(startDay, endDay, 'day', '[]')
      }
      isHovered={
        !!startDay &&
        !endDay &&
        !!hoveredDay &&
        hoveredDay.isAfter(startDay) &&
        day.isBetween(startDay, hoveredDay)
      }
      isStartDay={!!startDay && day.isSame(startDay, 'day')}
      isEndDay={
        (!!endDay && day.isSame(endDay, 'day')) ||
        (!!hoveredDay && day.isSame(hoveredDay, 'day'))
      }
    />
  )
}

export enum DateRangeShortCut {
  THIS_WEEK = 'THIS_WEEK',
  LAST_WEEK = 'LAST_WEEK',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  CURRENT_MONTH = 'CURRENT_MONTH',
  LAST_YEAR = 'LAST_YEAR',
}

export const DATE_RANGE_SHORTCUT_LABEL: Record<DateRangeShortCut, string> = {
  [DateRangeShortCut.THIS_WEEK]: 'date_range_input.label.this_week',
  [DateRangeShortCut.LAST_WEEK]: 'date_range_input.label.last_week',
  [DateRangeShortCut.LAST_7_DAYS]: 'date_range_input.label.last_7_days',
  [DateRangeShortCut.LAST_30_DAYS]: 'date_range_input.label.last_30_days',
  [DateRangeShortCut.CURRENT_MONTH]: 'date_range_input.label.current_month',
  [DateRangeShortCut.LAST_YEAR]: 'date_range_input.label.last_year',
}

type DateRangeInputProps = {
  variant?: TextFieldVariants
  disabled?: boolean
  label?: string
  defaultValue?: [string, string]
  defaultDateRangeShortCut?: DateRangeShortCut
  onChange?: (value: [Dayjs, Dayjs | null]) => void
  disablePast?: boolean
  disableFuture?: boolean
  value?: [string | null, string | null]
}

const DateRangeInput: React.FC<DateRangeInputProps> = ({
  variant = 'standard',
  onChange,
  label,
  defaultValue,
  defaultDateRangeShortCut = null,
  disabled = false,
  disablePast = false,
  disableFuture = false,
  value,
}) => {
  const { formatMessage } = useIntl()
  const [isPopOpen, setIsPopOpen] = useState(false)
  const [popEl, setPopEl] = useState<HTMLInputElement | null>(null)
  const [startDay, setStartDay] = useState<Dayjs | null>(null)
  const [endDay, setEndDay] = useState<Dayjs | null>(null)
  const [hoveredDay, setHoveredDay] = useState<Dayjs | null>(null)
  const [dateRangeShortCut, setDateRangeShortCut] =
    useState<DateRangeShortCut | null>(defaultDateRangeShortCut)

  useEffect(() => {
    if (defaultDateRangeShortCut === DateRangeShortCut.THIS_WEEK) {
      const today = dayjs()
      setStartDay(today.startOf('week'))
      setEndDay(today)
    } else if (defaultDateRangeShortCut === DateRangeShortCut.LAST_WEEK) {
      const today = dayjs()
      const prevWeek = today.subtract(7, 'day')
      setStartDay(prevWeek.startOf('week'))
      setEndDay(prevWeek.endOf('week'))
    } else if (defaultDateRangeShortCut === DateRangeShortCut.LAST_7_DAYS) {
      const today = dayjs()
      setStartDay(today.subtract(7, 'day'))
      setEndDay(today)
    } else if (defaultDateRangeShortCut === DateRangeShortCut.LAST_30_DAYS) {
      const today = dayjs()
      setStartDay(today.subtract(30, 'day'))
      setEndDay(today)
    } else if (defaultDateRangeShortCut === DateRangeShortCut.CURRENT_MONTH) {
      const today = dayjs()
      setStartDay(today.startOf('month'))
      setEndDay(today)
    } else if (defaultDateRangeShortCut === DateRangeShortCut.LAST_YEAR) {
      const today = dayjs()
      setStartDay(today.subtract(365, 'day'))
      setEndDay(today)
    }
  }, [])

  const handleOpenPop = (event: React.MouseEvent<HTMLInputElement>): void => {
    setPopEl(event.currentTarget)
    setIsPopOpen(true)
  }

  const handleClosePop = (): void => {
    setPopEl(null)
    setIsPopOpen(false)
  }

  const renderValue = useMemo((): string | undefined => {
    if (!value?.[0] && !value?.[1]) {
      return undefined
    }

    if (dateRangeShortCut) {
      return formatMessage({ id: DATE_RANGE_SHORTCUT_LABEL[dateRangeShortCut] })
    }

    return `${value?.[0] || ''} - ${value?.[1] || ''}`
  }, [value, dateRangeShortCut])

  const handleSelectDate = (day: Dayjs): void => {
    const updateSelection = (newStart: Dayjs, newEnd: Dayjs | null): void => {
      setDateRangeShortCut(null)
      setStartDay(newStart)
      setEndDay(newEnd)
      onChange?.([newStart, newEnd])

      if (!!newStart && !!newEnd) {
        handleClosePop()
      }
    }

    if (!startDay || endDay) {
      updateSelection(day, null)
    } else {
      updateSelection(
        day.isBefore(startDay, 'day') ? day : startDay,
        day.isBefore(startDay, 'day') ? endDay : day,
      )
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TextField
        aria-describedby="date-range-popover"
        disabled={disabled}
        label={label}
        variant={variant}
        value={renderValue}
        slotProps={{
          inputLabel: { shrink: !!startDay || !!endDay },
          input: {
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <CalendarIcon />
              </InputAdornment>
            ),
            sx: {
              cursor: 'pointer',
              paddingRight: variant !== 'standard' ? '8px' : 0,
            },
          },
        }}
        onClick={handleOpenPop}
        size="small"
        sx={{
          width: '240px',
        }}
      />

      <Popover
        id="date-range-popover"
        open={isPopOpen}
        anchorEl={popEl}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            style: {
              minWidth: popEl?.clientWidth,
            },
          },
        }}
      >
        <Box
          padding={1}
          sx={{
            maxHeight: 'calc(50vh)',
            overflow: 'auto',
          }}
        >
          <Stack direction="row">
            <Stack>
              <Button
                variant={
                  dateRangeShortCut === DateRangeShortCut.THIS_WEEK
                    ? 'contained'
                    : 'text'
                }
                onClick={() => {
                  const today = dayjs()
                  setStartDay(today.startOf('week'))
                  setEndDay(today)
                  onChange?.([today.startOf('week'), today])
                  handleClosePop()
                  setDateRangeShortCut(DateRangeShortCut.THIS_WEEK)
                }}
              >
                {formatMessage({
                  id: DATE_RANGE_SHORTCUT_LABEL[DateRangeShortCut.THIS_WEEK],
                })}
              </Button>
              <Button
                variant={
                  dateRangeShortCut === DateRangeShortCut.LAST_WEEK
                    ? 'contained'
                    : 'text'
                }
                onClick={() => {
                  const today = dayjs()
                  const prevWeek = today.subtract(7, 'day')
                  setStartDay(prevWeek.startOf('week'))
                  setEndDay(prevWeek.endOf('week'))
                  onChange?.([prevWeek.startOf('week'), prevWeek.endOf('week')])
                  handleClosePop()
                  setDateRangeShortCut(DateRangeShortCut.LAST_WEEK)
                }}
              >
                {formatMessage({
                  id: DATE_RANGE_SHORTCUT_LABEL[DateRangeShortCut.LAST_WEEK],
                })}
              </Button>
              <Button
                variant={
                  dateRangeShortCut === DateRangeShortCut.LAST_7_DAYS
                    ? 'contained'
                    : 'text'
                }
                onClick={() => {
                  const today = dayjs()
                  setStartDay(today.subtract(7, 'day'))
                  setEndDay(today)
                  onChange?.([today.subtract(7, 'day'), today])
                  handleClosePop()
                  setDateRangeShortCut(DateRangeShortCut.LAST_7_DAYS)
                }}
              >
                {formatMessage({
                  id: DATE_RANGE_SHORTCUT_LABEL[DateRangeShortCut.LAST_7_DAYS],
                })}
              </Button>
              <Button
                variant={
                  dateRangeShortCut === DateRangeShortCut.LAST_30_DAYS
                    ? 'contained'
                    : 'text'
                }
                onClick={() => {
                  const today = dayjs()
                  setStartDay(today.subtract(30, 'day'))
                  setEndDay(today)
                  onChange?.([today.subtract(30, 'day'), today])
                  handleClosePop()
                  setDateRangeShortCut(DateRangeShortCut.LAST_30_DAYS)
                }}
              >
                {formatMessage({
                  id: DATE_RANGE_SHORTCUT_LABEL[DateRangeShortCut.LAST_30_DAYS],
                })}
              </Button>
              <Button
                variant={
                  dateRangeShortCut === DateRangeShortCut.CURRENT_MONTH
                    ? 'contained'
                    : 'text'
                }
                onClick={() => {
                  const today = dayjs()
                  setStartDay(today.startOf('month'))
                  setEndDay(today)
                  onChange?.([today.startOf('month'), today])
                  handleClosePop()
                  setDateRangeShortCut(DateRangeShortCut.CURRENT_MONTH)
                }}
              >
                {formatMessage({
                  id: DATE_RANGE_SHORTCUT_LABEL[
                    DateRangeShortCut.CURRENT_MONTH
                  ],
                })}
              </Button>
              <Button
                variant={
                  dateRangeShortCut === DateRangeShortCut.LAST_YEAR
                    ? 'contained'
                    : 'text'
                }
                onClick={() => {
                  const today = dayjs()
                  setStartDay(today.subtract(365, 'day'))
                  setEndDay(today)
                  onChange?.([today.subtract(365, 'day'), today])
                  handleClosePop()
                  setDateRangeShortCut(DateRangeShortCut.LAST_YEAR)
                }}
              >
                {formatMessage({
                  id: DATE_RANGE_SHORTCUT_LABEL[DateRangeShortCut.LAST_YEAR],
                })}
              </Button>
            </Stack>
            <Divider orientation="vertical" flexItem />
            <DateCalendar
              defaultValue={
                defaultValue?.[0] ? dayjs(defaultValue[0]) : undefined
              }
              referenceDate={dayjs().subtract(1, 'month')}
              onChange={handleSelectDate}
              shouldDisableDate={(date) => {
                return (
                  (disablePast && date.isBefore(dayjs(new Date()), 'date')) ||
                  (disableFuture && date.isAfter(dayjs(new Date()), 'date'))
                )
              }}
              slots={{ day: Day }}
              slotProps={{
                day: (ownerState) =>
                  ({
                    startDay,
                    endDay,
                    hoveredDay,
                    onPointerEnter: () => {
                      setHoveredDay(ownerState.day)
                    },
                    onPointerLeave: () => {
                      setHoveredDay(null)
                    },
                  } as any),
              }}
            />
            <Divider orientation="vertical" flexItem />
            <DateCalendar
              defaultValue={
                defaultValue?.[1] ? dayjs(defaultValue[1]) : undefined
              }
              shouldDisableDate={(date) => {
                return (
                  (disablePast && date.isBefore(dayjs(new Date()), 'date')) ||
                  (disableFuture && date.isAfter(dayjs(new Date()), 'date'))
                )
              }}
              onChange={handleSelectDate}
              slots={{ day: Day }}
              slotProps={{
                day: (ownerState) =>
                  ({
                    startDay,
                    endDay,
                    hoveredDay,
                    onPointerEnter: () => {
                      setHoveredDay(ownerState.day)
                    },
                    onPointerLeave: () => {
                      setHoveredDay(null)
                    },
                  } as any),
              }}
            />
          </Stack>
        </Box>
      </Popover>
    </LocalizationProvider>
  )
}

export default DateRangeInput
