import { useContext, useEffect, useState } from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import {
  Link,
  Stack,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from '@mui/material'

import {
  hasUnsavedChangesState,
  isUnsavedConfirmDialogOpenState,
} from 'state/formStates'
import AuthContext from './context/AuthContext'
import { networkErrorState } from 'state/errorStates'
import { isUserLoadingState, userAccessState } from 'state/userStates'
import useApi from 'hooks/useApi'
import {
  AUTH_ERROR,
  Path,
  PREPORTI_SUPPORT_LOCALES_MAP,
} from './commonConstants'
import useRoute from 'hooks/useNavigate'
import { type NetworkError } from 'types'
import {
  currentLocaleState,
  isPortalSettingLoadingState,
  portalSettingState,
} from 'state/portalSettingStates'
import useMember from 'hooks/useMember'
import { Switch } from 'components/StyledComponents'
import { ERROR_CODE_MESSAGE } from 'errorConstants'
import { currentPortalAccessSelector } from 'state/memberStates'
import { type UserAccess } from 'components/role/roleTypes'
import Loader from 'components/loader/Loader'
import {
  selectedMapCenterState,
  selectedMapZoomState,
  currentMapCaseListScrollTopState,
  selectedCaseCategoriesState,
  selectedCasePeriodState,
  selectedCaseShareabilitiesState,
  selectedCaseStatusesState,
  keywordState,
  selectedPortalItemsState,
} from 'state/caseListStates'
import {
  CASE_PERIODS_DEFAULT,
  CASE_SHAREABILITIES_DEFAULT,
  CASE_STATUSES_DEFAULT,
} from 'components/case/caseConstants'

const AppBootstrap: React.FC = () => {
  const { formatMessage, locale } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const { goTo } = useRoute()
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const [isUnsavedConfirmDialogOpen, setIsUnsavedConfirmDialogOpen] =
    useRecoilState(isUnsavedConfirmDialogOpenState)
  const networkError = useRecoilValue(networkErrorState)
  const [isUserLoading, setIsUserLoading] = useRecoilState(isUserLoadingState)
  const { sendGetRequest, sendPostRequest } = useApi()
  const { authData, isAuthLoading } = useContext(AuthContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const portalId = searchParams.get('portalId')

  const { clearSession } = useMember()
  const setCurrentLocale = useSetRecoilState(currentLocaleState)
  const setPortalSetting = useSetRecoilState(portalSettingState)
  const setUserAccess = useSetRecoilState(userAccessState)
  const [isPortalSettingLoading, setIsPortalSettingLoading] = useRecoilState(
    isPortalSettingLoadingState,
  )
  const currentPortalMemberAccess = useRecoilValue(currentPortalAccessSelector)
  const [isTermsDialogOpen, setIsTermsDialogOpen] = useState(false)
  const [acceptTerm, setAcceptTerm] = useState(false)
  const setSelectedMapCenter = useSetRecoilState(selectedMapCenterState)
  const setSelectedMapZoom = useSetRecoilState(selectedMapZoomState)
  const setCurrentMapCaseListScrollTop = useSetRecoilState(
    currentMapCaseListScrollTopState,
  )
  const setSelectedCaseCategories = useSetRecoilState(
    selectedCaseCategoriesState,
  )
  const setSelectedCasePeriod = useSetRecoilState(selectedCasePeriodState)
  const setCaseShareabilities = useSetRecoilState(
    selectedCaseShareabilitiesState,
  )
  const setSelectedCaseStatuses = useSetRecoilState(selectedCaseStatusesState)
  const setKeyword = useSetRecoilState(keywordState)
  const setSelectedPortalItems = useSetRecoilState(selectedPortalItemsState)
  const analytics = getAnalytics()

  const { pathname } = window.location

  const loadPortalsMemberAccess = async (
    email: string | null,
  ): Promise<void> => {
    try {
      setIsUserLoading(true)

      const response = await sendPostRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals:resolveAccess`,
      )
      const userAccessData = (await response?.json()) as UserAccess
      setUserAccess(userAccessData)
      const isAdmin = userAccessData.userData.admin

      if (userAccessData) {
        const accessiblePortals = userAccessData.accessiblePortals.filter(
          (portal) => portal.memberData?.active,
        )

        if (!isAdmin) {
          setUserAccess({
            ...userAccessData,
            accessiblePortals,
          })
        }

        if (!accessiblePortals.length && !isAdmin) {
          clearSession()
          goTo(`${Path.LOGIN}?error=${AUTH_ERROR.NO_PORTAL}`)
          return
        }

        logEvent(analytics, 'web_user_login')

        if (!isAdmin && accessiblePortals?.length === 1) {
          setSearchParams({
            portalId: accessiblePortals[0].portalId,
          })
        } else if (pathname === '/') {
          goTo(Path.PORTALS_LIST)
        }
      } else if (isAdmin) {
        goTo(Path.SIGN_UP)
      } else {
        clearSession()
        goTo(`${Path.LOGIN}?error=${AUTH_ERROR.NO_USER}`)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsUserLoading(false)
    }
  }

  const loadPortalSetting = async (portalId: string): Promise<void> => {
    try {
      setIsPortalSettingLoading(true)
      const response = await sendGetRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${portalId}`,
      )
      const portalSettingData = await response?.json()

      setPortalSetting({
        ...portalSettingData,
        portalInfo: {
          customerProfile: {
            email: 'required',
            name: 'optional',
            phone: 'optional',
            address: 'optional',
            identifier: 'optional',
            company: 'optional',
            building: 'hidden',
            section: 'hidden',
            floor: 'hidden',
            unitApartment: 'optional',
          },
        },
      })

      const userLanguage = localStorage.getItem('userLanguage')
      const urlLang = searchParams.get('lang')
      let currentLocale =
        urlLang?.toUpperCase() ?? portalSettingData.defaultLanguage

      if (
        userLanguage &&
        portalSettingData.supportedLanguages.includes(userLanguage)
      ) {
        currentLocale = userLanguage
      }

      setCurrentLocale(currentLocale)
      document.title = portalSettingData.name
    } catch (error) {
      console.error(error)
    } finally {
      setIsPortalSettingLoading(false)
      setSelectedMapCenter(null)
      setSelectedMapZoom(null)
      setCurrentMapCaseListScrollTop(0)

      const caseFiltersString = localStorage.getItem('caseFilters')
      if (caseFiltersString) {
        const caseFilters = JSON.parse(caseFiltersString)
        setSelectedCaseCategories(caseFilters?.[portalId]?.categories ?? [])
        setSelectedPortalItems(caseFilters?.[portalId]?.items ?? [])
        setKeyword(caseFilters?.[portalId]?.search ?? '')
        setSelectedCasePeriod(
          caseFilters?.[portalId]?.period ?? CASE_PERIODS_DEFAULT,
        )
        setCaseShareabilities(
          caseFilters?.[portalId]?.shareabilities ??
            CASE_SHAREABILITIES_DEFAULT,
        )
        setSelectedCaseStatuses(
          caseFilters?.[portalId]?.statuses ?? CASE_STATUSES_DEFAULT,
        )
        setSelectedCasePeriod(
          caseFilters?.[portalId]?.period ?? CASE_PERIODS_DEFAULT,
        )
      }
    }
  }

  useEffect(() => {
    if (authData) {
      void loadPortalsMemberAccess(authData.email)

      const acceptTerm = localStorage.getItem('acceptTerm')
      if (!acceptTerm) {
        setIsTermsDialogOpen(true)
      }
    }
  }, [authData])

  useEffect(() => {
    if (!authData && !isAuthLoading && pathname === '/') {
      goTo(Path.LOGIN)
    }
  }, [authData, isAuthLoading])

  useEffect(() => {
    if (portalId && authData) {
      void loadPortalSetting(portalId)
    }
  }, [portalId, authData])

  useEffect(() => {
    if (currentPortalMemberAccess && pathname === '/') {
      if (currentPortalMemberAccess.accessibleSections.CASES) {
        goTo(Path.CASES_LIST)
      } else if (currentPortalMemberAccess.accessibleSections.CONTACTS) {
        goTo(Path.CONTACTS_LIST)
      } else if (currentPortalMemberAccess.accessibleSections.TASK_TEMPLATES) {
        goTo(Path.TASK_TEMPLATES_LIST)
      } else if (
        currentPortalMemberAccess.accessibleSections.COMMENT_TEMPLATES
      ) {
        goTo(Path.COMMENT_TEMPLATES_LIST)
      } else if (currentPortalMemberAccess.accessibleSections.CATEGORIES) {
        goTo(Path.CATEGORIES_LIST)
      } else if (currentPortalMemberAccess.accessibleSections.WORKFLOWS) {
        goTo(Path.WORKFLOWS_LIST)
      } else if (currentPortalMemberAccess.accessibleSections.STATS) {
        goTo(Path.STATISTICS)
      } else if (currentPortalMemberAccess.accessibleSections.MEMBERS) {
        goTo(Path.MEMBERS_LIST)
      } else if (currentPortalMemberAccess.accessibleSections.TEAMS) {
        goTo(Path.TEAMS_LIST)
      } else if (currentPortalMemberAccess.accessibleSections.CUSTOMERS) {
        goTo(Path.CUSTOMERS_LIST)
      } else if (currentPortalMemberAccess.accessibleSections.POLLS) {
        goTo(Path.POLLS_LIST)
      } else if (currentPortalMemberAccess.accessibleSections.NEWS_POSTS) {
        goTo(Path.NEWS_LIST)
      } else if (currentPortalMemberAccess.accessibleSections.ALERTS) {
        goTo(Path.ALERTS_LIST)
      } else if (currentPortalMemberAccess.accessibleSections.OFFERS) {
        goTo(Path.OFFERS_LIST)
      } else if (currentPortalMemberAccess.accessibleSections.SETTINGS) {
        goTo(`${Path.PORTALS_EDIT}/${portalId}`)
      } else if (currentPortalMemberAccess.accessibleSections.ITEMS) {
        goTo(Path.ITEMS_LIST)
      } else if (currentPortalMemberAccess.accessibleSections.ROLES) {
        goTo(Path.ROLES_LIST)
      } else {
        goTo(Path.PROFILE)
      }
    }
  }, [currentPortalMemberAccess, pathname])

  useEffect(() => {
    if (networkError) {
      try {
        const jsonData = JSON.parse(networkError) as NetworkError
        let errorTranslationKey = 'general.error.unknown_error'
        if (jsonData.code && !!ERROR_CODE_MESSAGE[jsonData.code]) {
          errorTranslationKey = ERROR_CODE_MESSAGE[jsonData.code]
        }
        enqueueSnackbar(formatMessage({ id: errorTranslationKey }), {
          variant: 'error',
        })
      } catch (e) {
        enqueueSnackbar(networkError, { variant: 'error' })
      }
    }
  }, [networkError])

  const handleDiscard = (): void => {
    setIsUnsavedConfirmDialogOpen(false)
    setHasUnsavedChanges(false)
  }

  const handleContinue = (): void => {
    setIsUnsavedConfirmDialogOpen(false)
  }

  const handleTermContinue = (): void => {
    localStorage.setItem('acceptTerm', 'accepted')
    setIsTermsDialogOpen(false)
  }

  return (
    <>
      <Dialog open={isUnsavedConfirmDialogOpen} maxWidth="xs">
        <DialogTitle sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'general.unsaved_confirm_dialog.title' })}
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'general.unsaved_confirm_dialog.content' })}
        </DialogContent>
        <DialogActions>
          <Stack width="100%" spacing={1}>
            <Button
              fullWidth
              onClick={handleContinue}
              autoFocus
              variant="contained"
            >
              {formatMessage({
                id: 'general.unsaved_confirm_dialog.button.continue',
              })}
            </Button>
            <Button fullWidth onClick={handleDiscard} variant="outlined">
              {formatMessage({
                id: 'general.unsaved_confirm_dialog.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog open={isTermsDialogOpen} maxWidth="xs">
        <DialogTitle>
          {formatMessage({ id: 'general.terms_dialog.title' })}
        </DialogTitle>
        <DialogContent>
          <Stack direction="row">
            <Box flexGrow={1}>
              {formatMessage(
                { id: 'general.terms_dialog.content' },
                {
                  terms_link: (
                    <Link
                      href={`https://www.preporti.com/${
                        PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
                      }terms-of-use`}
                      target="_blank"
                    >
                      {formatMessage({ id: 'login_footer.link.terms' })}
                    </Link>
                  ),
                  privacy_link: (
                    <Link
                      href={`https://www.preporti.com/${
                        PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
                      }privacy-policy`}
                      target="_blank"
                    >
                      {formatMessage({
                        id: 'login_footer.link.privacy_policy',
                      })}
                    </Link>
                  ),
                },
              )}
            </Box>
            <Switch
              data-testid="accept-term-switch"
              defaultChecked={acceptTerm}
              color="secondary"
              onChange={(e) => {
                setAcceptTerm(e.target.checked)
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="accept-term-button"
            onClick={handleTermContinue}
            autoFocus
            variant="contained"
            fullWidth
            disabled={!acceptTerm}
          >
            {formatMessage({
              id: 'general.button.continue',
            })}
          </Button>
        </DialogActions>
      </Dialog>

      <Outlet />

      {(isUserLoading || isPortalSettingLoading) && <Loader />}
    </>
  )
}

export default AppBootstrap
