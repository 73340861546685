import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import dayjs from 'dayjs'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import FormHelperText from '@mui/material/FormHelperText'
import Divider from '@mui/material/Divider'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import LanguageIcon from '@mui/icons-material/Language'
import EditIcon from '@mui/icons-material/Edit'
import ItemIcon from '@mui/icons-material/FolderCopy'
import AddIcon from '@mui/icons-material/Add'
import ChecklistIcon from '@mui/icons-material/Checklist'
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded'
import OpenIcon from '@mui/icons-material/Public'
import RestrictedIcon from '@mui/icons-material/Lock'
import DeleteIcon from '@mui/icons-material/Delete'

import {
  MainHeader,
  BackButtonGrey,
  SettingWrapper,
  LightSubHeader,
  SubSubHeader,
  FormFieldsWrapper,
  SelectBox,
} from 'components/StyledComponents'
import useRoute from 'hooks/useNavigate'
import { type LocalizedString, type Locale } from 'types'
import { Path } from '../commonConstants'
import {
  portalSettingState,
  sortedSupportLanguagesSelector,
} from 'state/portalSettingStates'
import MultiLineInput from 'components/form/MultiLineInput'
import { hasUnsavedChangesState } from 'state/formStates'
import TranslationDialog from 'components/TranslationDialog'
import useApi from 'hooks/useApi'
import {
  type Poll,
  type NewPoll,
  type PollFormData,
  type PollFormAnswer,
} from 'components/poll/pollTypes'
import {
  POLL_ANSWER_MAX_LENGTH,
  POLL_QUESTION_MAX_LENGTH,
  POLL_URL_MAX_LENGTH,
  PollResultExposure,
  PollVisibility,
} from 'components/poll/pollConstants'
import { type ItemBasic } from 'components/item/itemTypes'
import LogoWrapper from 'components/LogoWrapper'
import usePortalSetting from 'hooks/usePortalSetting'
import DateInput from 'components/form/DateInput'
import {
  ExposureAlwaysIcon,
  ExposureAfterIcon,
  ExposureNeverIcon,
} from 'components/icons/Icons'
import {
  convertDataToLocalizedString,
  convertLocalizedStringToData,
} from 'utils/stringUtils'
import IconButton from '@mui/material/IconButton'

const MAX_OPTIONS_LENGTH = 12

const PollEditPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { pollId } = useParams()
  const [searchParams] = useSearchParams()
  const isCopy = searchParams.get('isCopy')
  const { goTo } = useRoute()
  const supportLanguages = useRecoilValue(sortedSupportLanguagesSelector)
  const { sendPostRequest, sendPutRequest } = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const portalSetting = useRecoilValue(portalSettingState)
  const [isTranslationDialogOpen, setIsTranslationDialogOpen] = useState(false)
  const [translationDialogTitle, setTranslationDialogTitle] = useState('')
  const [translationInputRows, setTranslationInputRows] = useState(3)
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const translationContext = useRef<
    'questions' | 'links' | `answers.${number}` | null
  >(null)
  const [maxTranslationLength, setMaxTranslationLength] = useState(
    POLL_QUESTION_MAX_LENGTH,
  )
  const [items, setItems] = useState<ItemBasic[]>([])
  const [isLoadingItems, setIsLoadingItems] = useState(false)
  const [isExpiryDisabled, setIsExpiryDisabled] = useState(true)
  const { getLocalizedContent } = usePortalSetting()
  const [isActiveConfirmDialogOpen, setIsActiveConfirmDialogOpen] =
    useState(false)
  const { data: pollData, isLoading } = useSWR<Poll>(
    portalSetting && pollId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/polls/${pollId}`
      : null,
  )
  const {
    control,
    setValue,
    formState: { errors, isValid, isDirty, dirtyFields },
    getValues,
    trigger,
    watch,
  } = useForm<PollFormData>({
    mode: 'onTouched',
    defaultValues: {
      questions: {},
      // answers: ,
      visibility: PollVisibility.OPEN,
      resultConfiguration: {
        exposure: PollResultExposure.ALWAYS,
        retentionDays: 1,
      },
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'answers',
  })

  const questions = watch('questions')
  const answers = watch('answers')
  const visibility = watch('visibility')
  const resultConfiguration = watch('resultConfiguration')

  useEffect(() => {
    if (pollData) {
      setValue(
        'questions',
        convertLocalizedStringToData(pollData.question.texts),
      )
      setValue('links', convertLocalizedStringToData(pollData.question.links))
      setValue('itemId', pollData.item.id)

      if (pollData.question.answers) {
        const keys = Object.keys(pollData.question.answers)
        const answers: PollFormAnswer[] = []
        keys.forEach((key) => {
          answers.push(
            convertLocalizedStringToData(pollData.question.answers[key]),
          )
        })
        setValue('answers', answers)
      }

      if (pollData.expiry) {
        setValue('expiry', dayjs(pollData.expiry))
        setIsExpiryDisabled(false)
      }

      setValue('multichoice', pollData.question.multichoice)
      setValue('visibility', pollData.visibility)
      setValue('resultConfiguration', pollData.result.configuration)
    }
  }, [pollData])

  const getItems = async (): Promise<void> => {
    setIsLoadingItems(true)
    const response = await sendPostRequest(
      `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
        portalSetting?.id
      }/alerts:listAssignableItems`,
    )
    const itemsData = await response.json()
    setItems(itemsData)
    setIsLoadingItems(false)
  }

  const addOption = useCallback((): void => {
    if (answers?.length < MAX_OPTIONS_LENGTH && portalSetting) {
      append({
        [portalSetting.defaultLanguage]: '',
      })
    }
  }, [answers, portalSetting])

  useEffect(() => {
    if (portalSetting) {
      void getItems()

      if (!pollId) {
        setValue('answers', [
          { [portalSetting.defaultLanguage]: '' },
          { [portalSetting.defaultLanguage]: '' },
        ])
      }
    }
  }, [portalSetting, pollId])

  useEffect(() => {
    setHasUnsavedChanges(isDirty)
  }, [isDirty])

  const addOrUpdatePoll = useCallback(
    async (data: PollFormData, isActive: boolean): Promise<void> => {
      if (!portalSetting) {
        return
      }

      try {
        setIsSaving(true)
        const answers: Record<string, LocalizedString[]> = {}
        if (data.answers.length) {
          data.answers.forEach((answer, index) => {
            answers[index.toString()] = convertDataToLocalizedString(answer)
          })
        }
        const formData: NewPoll = {
          itemId: data.itemId,
          question: {
            texts: convertDataToLocalizedString(data.questions),
            links: convertDataToLocalizedString(data.links),
            answers,
            multichoice: data.multichoice,
          },
          visibility: data.visibility,
          active: isActive,
          resultConfiguration: data.resultConfiguration,
        }

        if (data.expiry) {
          formData.expiry = data.expiry.format()
        }

        if (pollId && !isCopy) {
          await sendPutRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting?.id
            }/polls/${pollId}`,
            formData,
          )
        } else {
          await sendPostRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting?.id
            }/polls`,
            formData,
          )
        }

        setHasUnsavedChanges(false)
        enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
          variant: 'success',
        })

        goTo(Path.POLLS_LIST, true)
      } catch (error) {
        console.error(error)
      } finally {
        setIsSaving(false)
      }
    },
    [portalSetting, pollId],
  )

  const handleGoBack = (): void => {
    goTo(Path.POLLS_LIST)
  }

  const handleOpenQuestionTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = 'questions'
    setMaxTranslationLength(POLL_QUESTION_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
    setTranslationInputRows(3)
  }

  const handleOpenUrlTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = 'links'
    setMaxTranslationLength(POLL_URL_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
    setTranslationInputRows(1)
  }

  const handleOpenOptionTranslationDialog = (index: number): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = `answers.${index}`
    setMaxTranslationLength(POLL_ANSWER_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
    setTranslationInputRows(1)
  }

  const handleSaveTranslation = (data: Record<Locale, string>): void => {
    if (translationContext.current) {
      setValue(translationContext.current, data, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      })
      void trigger(translationContext.current)
    }
    setIsTranslationDialogOpen(false)
  }

  const handleCloseTranslationDialog = (): void => {
    setIsTranslationDialogOpen(false)
  }

  const getTranslationDefaultLanguageValue = useCallback((): Record<
    string,
    string
  > => {
    if (translationContext.current) {
      return getValues(translationContext.current)
    }

    return {}
  }, [translationContext.current])

  const validateTranslations = (
    name: 'questions' | 'links' | `answers.${number}`,
  ): null | string => {
    if (!portalSetting) {
      return null
    }

    const data = getValues(name)

    let missingTranslations: Locale[] = [...portalSetting.supportedLanguages]

    if (data) {
      const keys = Object.keys(data)
      keys.forEach((key) => {
        if (data[key]) {
          missingTranslations = missingTranslations.filter(
            (item) => item !== key,
          )
        }
      })

      if (missingTranslations.length) {
        return missingTranslations.join(', ')
      }
    }

    return null
  }

  const missingQuestionsTranslation = useMemo(
    () => !!dirtyFields.questions && !!validateTranslations('questions'),
    [questions],
  )

  const shouldDisableSaving = useMemo(
    () =>
      !isValid ||
      Object.keys(errors).length > 0 ||
      isLoadingItems ||
      isSaving ||
      missingQuestionsTranslation ||
      answers.length < 2,

    [
      isValid,
      errors,
      isLoadingItems,
      isSaving,
      portalSetting?.type,
      missingQuestionsTranslation,
      answers,
    ],
  )

  const handleToggleExpirationDate = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setValue('expiry', null)
    setIsExpiryDisabled(!event.target.checked)
  }

  const handleSaveAsActive = (): void => {
    const formData = getValues()
    void addOrUpdatePoll(formData, true)
  }

  const handleSaveAsDraft = (): void => {
    const formData = getValues()
    void addOrUpdatePoll(formData, false)
  }

  const handleCloseActiveConfirmDialog = (): void => {
    setIsActiveConfirmDialogOpen(false)
  }

  const handleSetActive = (): void => {
    setIsActiveConfirmDialogOpen(true)
  }

  if ((pollId && isLoading) || !portalSetting) {
    return <CircularProgress />
  }

  return (
    <>
      <Stack height={'100%'} width={'100%'}>
        <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
          <Stack flexGrow={1} direction="row" spacing={1}>
            <BackButtonGrey
              onClick={handleGoBack}
              size="small"
              aria-label={formatMessage({
                id: 'general.icon_button.go_back',
              })}
            >
              <ArrowBackIcon />
            </BackButtonGrey>

            <MainHeader>
              {pollId && !isCopy && formatMessage({ id: 'poll_edit.header' })}
              {pollId && isCopy && formatMessage({ id: 'poll_copy.header' })}
              {!pollId && formatMessage({ id: 'poll_add.header' })}
            </MainHeader>
          </Stack>

          {(!pollId || pollData?._operations.canActivate || isCopy) && (
            <LoadingButton
              variant="contained"
              size="small"
              type="submit"
              disabled={shouldDisableSaving}
              loading={isSaving}
              color="secondary"
              onClick={handleSetActive}
            >
              {formatMessage({ id: 'poll_edit.button.activate' })}
            </LoadingButton>
          )}

          <LoadingButton
            variant="outlined"
            size="small"
            type="submit"
            disabled={shouldDisableSaving}
            loading={isSaving}
            onClick={handleSaveAsDraft}
          >
            {formatMessage({
              id:
                pollId && !isCopy
                  ? 'general.button.save'
                  : 'poll_edit.button.save_as_draft',
            })}
          </LoadingButton>
        </Stack>

        <SettingWrapper width="100%" flexGrow={1}>
          <Stack paddingY={2} paddingX={4} spacing={2}>
            <LightSubHeader>
              {formatMessage({
                id: 'poll_edit.subheader.poll_details',
              })}
            </LightSubHeader>

            <Stack spacing={2}>
              <Box>
                {supportLanguages.length > 1 && (
                  <Stack direction="row" alignItems="center">
                    <Box flexGrow={1}>
                      <FormHelperText error>
                        {!!dirtyFields.questions &&
                          !!validateTranslations('questions') &&
                          formatMessage(
                            {
                              id: 'portal_item_edit.error.missing_translations',
                            },
                            {
                              missingTranslations:
                                validateTranslations('questions'),
                            },
                          )}
                      </FormHelperText>
                    </Box>
                    <Button
                      size="small"
                      startIcon={<EditIcon />}
                      onClick={handleOpenQuestionTranslationDialog}
                    >
                      {formatMessage({
                        id: 'portal_item_edit.button.manage_localization',
                      })}
                    </Button>
                  </Stack>
                )}

                <Controller
                  name={`questions.${portalSetting.defaultLanguage}`}
                  control={control}
                  rules={{
                    maxLength: POLL_QUESTION_MAX_LENGTH,
                    required: true,
                  }}
                  defaultValue={''}
                  render={({ field }) => (
                    <MultiLineInput
                      {...field}
                      required
                      maxLength={POLL_QUESTION_MAX_LENGTH}
                      error={
                        !!errors?.questions?.[portalSetting.defaultLanguage]
                      }
                      label={`${formatMessage({
                        id: 'poll_edit.label.question',
                      })}${
                        supportLanguages.length < 2
                          ? ''
                          : ` (${portalSetting.defaultLanguage})`
                      }`}
                      variant="outlined"
                      fullWidth
                      rows={3}
                    />
                  )}
                />
                {errors.questions?.[portalSetting.defaultLanguage]?.type ===
                  'maxLength' && (
                  <FormHelperText error>
                    {formatMessage(
                      {
                        id: 'general.error.max_length',
                      },
                      {
                        max: POLL_QUESTION_MAX_LENGTH,
                      },
                    )}
                  </FormHelperText>
                )}
              </Box>

              <Stack width="100%">
                {supportLanguages.length > 1 && (
                  <Stack direction="row" alignItems="center">
                    <Box flexGrow={1}>
                      <FormHelperText error>
                        {!!dirtyFields.links &&
                          !!validateTranslations('links') &&
                          formatMessage(
                            {
                              id: 'portal_item_edit.error.missing_translations',
                            },
                            {
                              missingTranslations:
                                validateTranslations('links'),
                            },
                          )}
                      </FormHelperText>
                    </Box>
                    <Button
                      size="small"
                      startIcon={<EditIcon />}
                      onClick={handleOpenUrlTranslationDialog}
                    >
                      {formatMessage({
                        id: 'portal_item_edit.button.manage_localization',
                      })}
                    </Button>
                  </Stack>
                )}

                <FormControl>
                  <Controller
                    name={`links.${portalSetting.defaultLanguage}`}
                    control={control}
                    defaultValue=""
                    rules={{
                      maxLength: POLL_URL_MAX_LENGTH,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.questions}
                        size="small"
                        label={`${formatMessage({
                          id: 'poll_edit.label.url',
                        })}${
                          supportLanguages.length < 2
                            ? ''
                            : ` (${portalSetting.defaultLanguage})`
                        }`}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LanguageIcon fontSize="small" color="primary" />
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Stack>

              <Stack width="100%" spacing={1}>
                <SubSubHeader>
                  {formatMessage({
                    id: 'poll_edit.subheader.answers',
                  })}
                </SubSubHeader>
                <FormFieldsWrapper>
                  {fields.map((answer, index) => (
                    <Stack key={index} width="100%">
                      {supportLanguages.length > 1 && (
                        <Stack direction="row" alignItems="center">
                          <Box flexGrow={1}>
                            <FormHelperText error>
                              {!!dirtyFields.answers?.[index] &&
                                !!validateTranslations(`answers.${index}`) &&
                                formatMessage(
                                  {
                                    id: 'portal_item_edit.error.missing_translations',
                                  },
                                  {
                                    missingTranslations: validateTranslations(
                                      `answers.${index}`,
                                    ),
                                  },
                                )}
                            </FormHelperText>
                          </Box>
                          <Button
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={(): void => {
                              handleOpenOptionTranslationDialog(index)
                            }}
                          >
                            {formatMessage({
                              id: 'portal_item_edit.button.manage_localization',
                            })}
                          </Button>
                        </Stack>
                      )}

                      <FormControl>
                        <Controller
                          name={`answers.${index}.${portalSetting.defaultLanguage}`}
                          control={control}
                          rules={{
                            maxLength: POLL_ANSWER_MAX_LENGTH,
                          }}
                          defaultValue={''}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              error={!!errors.answers}
                              size="small"
                              label={`${formatMessage(
                                {
                                  id: 'poll_edit.label.option',
                                },
                                { index: index + 1 },
                              )}${
                                supportLanguages.length < 2
                                  ? ''
                                  : ` (${portalSetting.defaultLanguage})`
                              }`}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </FormControl>

                      <Box textAlign="right">
                        <IconButton
                          size="small"
                          onClick={(): void => {
                            remove(index)
                          }}
                          aria-label={formatMessage({
                            id: 'general.icon_button.delete',
                          })}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    </Stack>
                  ))}

                  <Box textAlign="right" sx={{ bg: 'white' }}>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={addOption}
                      disabled={answers?.length >= MAX_OPTIONS_LENGTH}
                    >
                      {formatMessage({ id: 'poll_edit.button.add_option' })}
                    </Button>
                  </Box>
                </FormFieldsWrapper>

                <FormFieldsWrapper>
                  <FormControlLabel
                    value="start"
                    disableTypography
                    control={
                      <Controller
                        name="multichoice"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <Switch
                            {...field}
                            checked={field.value}
                            color="primary"
                            onChange={(e) => {
                              field.onChange(e)
                              setValue('multichoice', e.target.checked)
                            }}
                          />
                        )}
                      />
                    }
                    label={
                      <Stack direction="row" spacing={1} width="100%">
                        <ChecklistIcon />
                        <Typography>
                          {formatMessage({
                            id: 'poll_edit.label.multichoice',
                          })}
                        </Typography>
                      </Stack>
                    }
                    labelPlacement="start"
                  />
                </FormFieldsWrapper>

                <FormFieldsWrapper>
                  <FormControlLabel
                    value="start"
                    disableTypography
                    control={
                      <Switch
                        checked={!isExpiryDisabled}
                        onChange={handleToggleExpirationDate}
                        color="primary"
                      />
                    }
                    label={
                      <Stack direction="row" spacing={1} width="100%">
                        <HourglassBottomRoundedIcon />
                        <Typography>
                          {formatMessage({
                            id: 'poll_edit.label.expiry',
                          })}
                        </Typography>
                      </Stack>
                    }
                    labelPlacement="start"
                  />

                  <FormControl>
                    <Controller
                      name="expiry"
                      control={control}
                      render={({ field }) => (
                        <DateInput
                          {...field}
                          disabled={isExpiryDisabled}
                          label={formatMessage({
                            id: 'poll_edit.label.expiry',
                          })}
                          disablePast={true}
                          showTime={true}
                        />
                      )}
                    />
                  </FormControl>
                </FormFieldsWrapper>
              </Stack>
            </Stack>
          </Stack>

          <Divider />

          <Stack paddingY={2} paddingX={4} spacing={2}>
            <LightSubHeader>
              {formatMessage({
                id: 'poll_edit.subheader.visibility_in_app',
              })}
            </LightSubHeader>

            <Stack spacing={2} width="100%">
              <FormControl fullWidth error={!!errors.itemId}>
                <InputLabel id="item-label" size="small" required>
                  {formatMessage({
                    id: 'alert_edit.label.item',
                  })}
                </InputLabel>
                <Controller
                  name="itemId"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  defaultValue={''}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="item-label"
                      label={formatMessage({
                        id: 'alert_edit.label.item',
                      })}
                      startAdornment={
                        <InputAdornment position="start">
                          <ItemIcon fontSize="small" color="primary" />
                        </InputAdornment>
                      }
                      size="small"
                      fullWidth
                      variant="outlined"
                    >
                      {items.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <LogoWrapper
                              size={22}
                              url={item.logoUrl}
                              alt={getLocalizedContent(item.names)}
                            ></LogoWrapper>
                            <Typography>
                              {getLocalizedContent(item.names)}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>

              <Controller
                name="visibility"
                control={control}
                defaultValue={PollVisibility.OPEN}
                render={({ field }) => (
                  <Stack direction={'row'}>
                    <SelectBox
                      spacing={1}
                      direction={'row'}
                      alignItems="center"
                      selected={
                        visibility === PollVisibility.OPEN ? 'selected' : ''
                      }
                      onClick={() => {
                        setValue('visibility', PollVisibility.OPEN)
                      }}
                    >
                      <Stack flexGrow={1} direction="row" spacing={1}>
                        <Stack alignItems="center" justifyContent="center">
                          <OpenIcon fontSize="large" />
                        </Stack>
                        <Stack>
                          <SubSubHeader>
                            {formatMessage({
                              id: 'poll_edit.label.visibility.open',
                            })}
                          </SubSubHeader>
                          <Typography variant="caption">
                            {formatMessage({
                              id: 'poll_edit.description.visibility.open',
                            })}
                          </Typography>
                        </Stack>
                      </Stack>
                    </SelectBox>
                    <SelectBox
                      spacing={1}
                      direction={'row'}
                      alignItems="center"
                      selected={
                        visibility === PollVisibility.RESTRICTED
                          ? 'selected'
                          : ''
                      }
                      onClick={() => {
                        setValue('visibility', PollVisibility.RESTRICTED)
                      }}
                    >
                      <Stack flexGrow={1} direction="row" spacing={1}>
                        <Stack alignItems="center" justifyContent="center">
                          <RestrictedIcon fontSize="large" />
                        </Stack>
                        <Stack>
                          <SubSubHeader>
                            {formatMessage({
                              id: 'poll_edit.label.visibility.restricted',
                            })}
                          </SubSubHeader>
                          <Typography variant="caption">
                            {formatMessage({
                              id: 'poll_edit.description.visibility.restricted',
                            })}
                          </Typography>
                        </Stack>
                      </Stack>
                    </SelectBox>
                  </Stack>
                )}
              />

              <SubSubHeader>
                {formatMessage({
                  id: 'poll_edit.subheader.result_exposure',
                })}
              </SubSubHeader>
              <Controller
                name="resultConfiguration.exposure"
                control={control}
                defaultValue={PollResultExposure.ALWAYS}
                render={({ field }) => (
                  <Stack direction={'row'} spacing={0.1}>
                    <SelectBox
                      spacing={1}
                      alignItems="center"
                      textAlign="center"
                      selected={
                        resultConfiguration.exposure ===
                        PollResultExposure.ALWAYS
                          ? 'selected'
                          : ''
                      }
                      onClick={() => {
                        setValue(
                          'resultConfiguration.exposure',
                          PollResultExposure.ALWAYS,
                        )
                      }}
                    >
                      <ExposureAlwaysIcon fontSize="small" />
                      <SubSubHeader>
                        {formatMessage({
                          id: 'poll_edit.label.exposure.always',
                        })}
                      </SubSubHeader>

                      <Typography variant="caption">
                        {formatMessage({
                          id: 'poll_edit.description.exposure.always',
                        })}
                      </Typography>
                    </SelectBox>
                    <SelectBox
                      spacing={1}
                      alignItems="center"
                      textAlign="center"
                      selected={
                        resultConfiguration.exposure ===
                        PollResultExposure.AFTER_POLL_END
                          ? 'selected'
                          : ''
                      }
                      onClick={() => {
                        setValue(
                          'resultConfiguration.exposure',
                          PollResultExposure.AFTER_POLL_END,
                        )
                      }}
                    >
                      <ExposureAfterIcon fontSize="small" />
                      <SubSubHeader>
                        {formatMessage({
                          id: 'poll_edit.label.exposure.after_poll_end',
                        })}
                      </SubSubHeader>

                      <Typography variant="caption">
                        {formatMessage({
                          id: 'poll_edit.description.exposure.after_poll_end',
                        })}
                      </Typography>
                    </SelectBox>
                    <SelectBox
                      spacing={1}
                      alignItems="center"
                      textAlign="center"
                      selected={
                        resultConfiguration.exposure ===
                        PollResultExposure.NEVER
                          ? 'selected'
                          : ''
                      }
                      onClick={() => {
                        setValue(
                          'resultConfiguration.exposure',
                          PollResultExposure.NEVER,
                        )
                      }}
                    >
                      <ExposureNeverIcon fontSize="small" />
                      <SubSubHeader>
                        {formatMessage({
                          id: 'poll_edit.label.exposure.never',
                        })}
                      </SubSubHeader>

                      <Typography variant="caption">
                        {formatMessage({
                          id: 'poll_edit.description.exposure.never',
                        })}
                      </Typography>
                    </SelectBox>
                  </Stack>
                )}
              />

              <FormFieldsWrapper
                sx={{
                  display:
                    resultConfiguration.exposure === PollResultExposure.NEVER
                      ? 'none'
                      : 'block',
                }}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Box flexGrow={1}>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'poll_edit.description.retention_days',
                      })}
                    </Typography>
                  </Box>
                  <FormControl
                    error={!!errors.resultConfiguration?.retentionDays}
                  >
                    <Controller
                      name="resultConfiguration.retentionDays"
                      control={control}
                      rules={{
                        validate: (val): boolean => {
                          if (!!val && !Number.isNaN(Number(val)) && val > 0) {
                            return true
                          }
                          return false
                        },
                      }}
                      defaultValue={1}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required={true}
                          type="number"
                          error={!!errors.resultConfiguration?.retentionDays}
                          size="small"
                          InputProps={{
                            endAdornment: formatMessage({
                              id: 'portal_item_edit.visibility_period.days',
                            }),
                          }}
                          variant="outlined"
                        />
                      )}
                    />
                    {!!errors.resultConfiguration?.retentionDays && (
                      <FormHelperText>
                        {formatMessage({
                          id: 'portal_item_edit.visibility_period.days.error',
                        })}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </FormFieldsWrapper>
            </Stack>
          </Stack>
        </SettingWrapper>
      </Stack>

      <TranslationDialog
        isOpen={isTranslationDialogOpen}
        title={translationDialogTitle}
        rows={translationInputRows}
        defaultValue={getTranslationDefaultLanguageValue()}
        maxLength={maxTranslationLength}
        onSave={handleSaveTranslation}
        onClose={handleCloseTranslationDialog}
      />

      <Dialog open={isActiveConfirmDialogOpen} maxWidth="xs">
        <DialogTitle sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'poll_list.dialog.active.title' })}
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'alert_list.dialog.active.content' })}
        </DialogContent>
        <DialogActions>
          <Stack width="100%" spacing={1}>
            <LoadingButton
              loading={isSaving}
              fullWidth
              onClick={handleSaveAsActive}
              autoFocus
              variant="contained"
              color="error"
            >
              {formatMessage({
                id: 'alert_list.button.set_active',
              })}
            </LoadingButton>
            <Button
              fullWidth
              onClick={handleCloseActiveConfirmDialog}
              variant="outlined"
              color="error"
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PollEditPage
