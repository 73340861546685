import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { useRecoilValue } from 'recoil'
import { nanoid } from 'nanoid'
import dayjs, { type Dayjs } from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isBetweenPlugin from 'dayjs/plugin/isBetween'
import styled from '@mui/material/styles/styled'
import {
  FormControl,
  InputLabel,
  Select,
  Button,
  Box,
  CircularProgress,
  Stack,
  Input,
  MenuItem,
  Checkbox,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from '@mui/material'
import { type SelectChangeEvent } from '@mui/material/Select'
import { LineChart } from '@mui/x-charts/LineChart'
import { type LineSeriesType } from '@mui/x-charts'
import { DataGrid, useGridApiRef, type GridColDef } from '@mui/x-data-grid'
import InfoIcon from '@mui/icons-material/InfoOutlined'

import { MainHeader, SubHeader } from 'components/StyledComponents'
import { portalSettingState } from 'state/portalSettingStates'
import DateRangeInput, {
  DateRangeShortCut,
} from 'components/form/DateRangeInput'
import { DEFAULT_DATE_FORMAT } from 'commonConstants'
import {
  type TeamMetrics,
  type MemberMetrics,
  type CategoryCaseMetric,
  type DataSet,
  type Statistics,
} from 'components/statistics/statisticsTypes'
import usePortalSetting from 'hooks/usePortalSetting'
import { sortCategories } from 'utils/categoryUtils'
import CategorySelect from 'components/category/select/CategorySelect'
import { type CategoryBasic } from 'components/category/categoryTypes'
import { type MakeOptional } from '@mui/x-charts/models/helpers'
import { getCaseStatusColor } from 'components/case/caseUtils'
import { CaseStatus, CaseTaskStatus } from 'components/case/caseConstants'
import { getCaseTaskStatusColor } from 'components/task/taskConstants'
import useURLState from 'hooks/useURLState'

dayjs.extend(weekOfYear)
dayjs.extend(isBetweenPlugin)

const ViewTabsWrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
`

type ChartSeries = MakeOptional<LineSeriesType, 'type'>

enum CurrentView {
  CASES = 'CASES',
  TEAM_TASKS = 'TEAM_TASKS',
  MEMBER_TASKS = 'MEMBER_TASKS',
}

enum ChartType {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

const StatisticsPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const portalSetting = useRecoilValue(portalSettingState)
  const [startDate, setStartDate] = useURLState<string | null>(
    'startDate',
    null,
  )
  const [endDate, setEndDate] = useURLState<string | null>('endDate', null)
  const [currentView, setCurrentView] = useURLState<CurrentView | null>(
    'currentView',
    null,
  )
  const { getLocalizedContent } = usePortalSetting()
  const [selectedItemsIds, setSelectedItemsIds] = useURLState<string[]>(
    'selectedItemsIds',
    [],
  )
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useURLState<
    string[]
  >('selectedCategoriesIds', [])
  const [selectedTeamId, setSelectedTeamId] = useURLState<string>(
    'selectedTeamId',
    '-1',
  )
  const [selectedMemberId, setSelectedMemberId] = useURLState<string>(
    'selectedMemberId',
    '-1',
  )
  const [chartType, setChartType] = useURLState<ChartType>(
    'chartType',
    ChartType.WEEK,
  )
  const [dataset, setDataset] = useState<DataSet[]>([])
  const [chartSeries, setChartSeries] = useState<ChartSeries[]>([])

  const { data: statisticsData, isLoading } = useSWR<Statistics>(
    portalSetting && startDate
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/stats?from=${startDate}&${endDate ? `to=${endDate}` : ''}`
      : null,
  )
  const dataTableRef = useGridApiRef()

  const dateFormat = useMemo((): string => {
    const datetimeFormat = portalSetting
      ? portalSetting.dateFormat
      : DEFAULT_DATE_FORMAT
    return datetimeFormat.split(' ')[0].toUpperCase()
  }, [portalSetting])

  useEffect((): void => {
    const today = dayjs()
    setStartDate(today.subtract(30, 'day').format('YYYY-MM-DD'))
    setEndDate(today.format('YYYY-MM-DD'))
  }, [])

  useEffect((): void => {
    if (currentView === CurrentView.CASES) {
      setChartSeries([
        {
          id: 'casesCreated',
          curve: 'linear',
          dataKey: 'casesCreated',
          label: formatMessage({
            id: 'statistics.chart.label.cases_created',
          }),
          color: getCaseStatusColor(CaseStatus.NEW),
        },
        {
          id: 'casesResolved',
          curve: 'linear',
          dataKey: 'casesResolved',
          label: formatMessage({
            id: 'statistics.chart.label.cases_resolved',
          }),
          color: getCaseStatusColor(CaseStatus.COMPLETED),
        },
      ])
    } else {
      setChartSeries([
        {
          id: 'tasksAssigned',
          curve: 'linear',
          dataKey: 'tasksAssigned',
          label: formatMessage({
            id: 'statistics.chart.label.tasks_assigned',
          }),
          color: getCaseTaskStatusColor(CaseTaskStatus.ASSIGNED),
        },
        {
          id: 'tasksResolved',
          curve: 'linear',
          dataKey: 'tasksResolved',
          label: formatMessage({
            id: 'statistics.chart.label.tasks_resolved',
          }),
          color: getCaseTaskStatusColor(CaseTaskStatus.DONE),
        },
      ])
    }
  }, [currentView])

  useEffect((): void => {
    if (!currentView) {
      if (
        statisticsData?.categoryCaseMetrics &&
        statisticsData?.categoryCaseMetrics.length > 0
      ) {
        setCurrentView(CurrentView.CASES)
      } else if (
        statisticsData?.teamMetrics &&
        statisticsData?.teamMetrics.length > 0
      ) {
        setCurrentView(CurrentView.TEAM_TASKS)
      } else if (
        statisticsData?.memberMetrics &&
        statisticsData?.memberMetrics.length > 0
      ) {
        setCurrentView(CurrentView.MEMBER_TASKS)
      }
    } else if (statisticsData) {
      if (
        currentView === CurrentView.CASES &&
        !statisticsData.categoryCaseMetrics
      ) {
        setCurrentView(null)
      } else if (
        currentView === CurrentView.TEAM_TASKS &&
        !statisticsData.teamMetrics
      ) {
        setCurrentView(null)
      } else if (
        currentView === CurrentView.MEMBER_TASKS &&
        !statisticsData.memberMetrics
      ) {
        setCurrentView(null)
      }
    }
  }, [currentView, statisticsData])

  const getCategoriesDataset = (
    categories: CategoryCaseMetric[],
    itemIds: string[],
    categoryIds: string[],
  ): DataSet[] => {
    let results: DataSet[] = []

    categories.forEach((category) => {
      if (category.subcategories.length > 0) {
        results = [
          ...results,
          ...getCategoriesDataset(category.subcategories, itemIds, categoryIds),
        ]
      } else if (
        categoryIds.length === 0 ||
        categoryIds.includes(category.id)
      ) {
        const casesCreatedKeys = Object.keys(category.casesCreated)
        casesCreatedKeys.forEach((key) => {
          const casesCreatedEvents =
            itemIds.length === 0
              ? category.casesCreated[key]
              : category.casesCreated[key].filter((event) =>
                  itemIds.includes(event.itemId),
                )

          casesCreatedEvents.forEach((event) => {
            results = [
              ...results,
              {
                date: key,
                casesCreated: event.counter,
              },
            ]
          })
        })

        const casesResolvedKeys = Object.keys(category.casesResolved)
        casesResolvedKeys.forEach((key) => {
          const casesResolvedEvents =
            itemIds.length === 0
              ? category.casesResolved[key]
              : category.casesResolved[key].filter((event) =>
                  itemIds.includes(event.itemId),
                )

          casesResolvedEvents.forEach((event) => {
            results = [
              ...results,
              {
                date: key,
                casesResolved: event.counter,
              },
            ]
          })
        })
      }
    })

    return results
  }

  const getTeamsDataset = (
    teamMetrics: TeamMetrics[],
    itemIds: string[],
    teamId: string,
  ): DataSet[] => {
    let results: DataSet[] = []

    teamMetrics.forEach((teamMetric) => {
      if (teamId === '-1' || teamMetric.team.id === teamId) {
        const tasksAssignedKeys = Object.keys(teamMetric.tasksAssigned)
        tasksAssignedKeys.forEach((key) => {
          const tasksAssignedEvents =
            itemIds.length === 0
              ? teamMetric.tasksAssigned[key]
              : teamMetric.tasksAssigned[key].filter((event) =>
                  itemIds.includes(event.itemId),
                )

          tasksAssignedEvents.forEach((event) => {
            results = [
              ...results,
              {
                date: key,
                tasksAssigned: event.counter,
              },
            ]
          })
        })

        const tasksResolvedKeys = Object.keys(teamMetric.tasksResolved)
        tasksResolvedKeys.forEach((key) => {
          const tasksResolvedEvents =
            itemIds.length === 0
              ? teamMetric.tasksResolved[key]
              : teamMetric.tasksResolved[key].filter((event) =>
                  itemIds.includes(event.itemId),
                )

          tasksResolvedEvents.forEach((event) => {
            results = [
              ...results,
              {
                date: key,
                tasksResolved: event.counter,
              },
            ]
          })
        })
      }
    })

    return results
  }

  const getMembersDataset = (
    memberMetrics: MemberMetrics[],
    itemIds: string[],
    memberId: string,
  ): DataSet[] => {
    let results: DataSet[] = []

    memberMetrics.forEach((memberMetric) => {
      if (memberId === '-1' || memberMetric.member.id === memberId) {
        const tasksAssignedKeys = Object.keys(memberMetric.tasksAssigned)
        tasksAssignedKeys.forEach((key) => {
          const tasksAssignedEvents =
            itemIds.length === 0
              ? memberMetric.tasksAssigned[key]
              : memberMetric.tasksAssigned[key].filter((event) =>
                  itemIds.includes(event.itemId),
                )

          tasksAssignedEvents.forEach((event) => {
            results = [
              ...results,
              {
                date: key,
                tasksAssigned: event.counter,
              },
            ]
          })
        })

        const tasksResolvedKeys = Object.keys(memberMetric.tasksResolved)
        tasksResolvedKeys.forEach((key) => {
          const tasksResolvedEvents =
            itemIds.length === 0
              ? memberMetric.tasksResolved[key]
              : memberMetric.tasksResolved[key].filter((event) =>
                  itemIds.includes(event.itemId),
                )

          tasksResolvedEvents.forEach((event) => {
            results = [
              ...results,
              {
                date: key,
                tasksResolved: event.counter,
              },
            ]
          })
        })
      }
    })

    return results
  }

  const rawDataset = useMemo(() => {
    const results: DataSet[] = []

    if (!statisticsData) {
      return results
    }

    if (
      currentView === CurrentView.CASES &&
      statisticsData.categoryCaseMetrics
    ) {
      return getCategoriesDataset(
        statisticsData.categoryCaseMetrics,
        selectedItemsIds,
        selectedCategoriesIds,
      )
    }

    if (currentView === CurrentView.TEAM_TASKS && statisticsData.teamMetrics) {
      return getTeamsDataset(
        statisticsData.teamMetrics,
        selectedItemsIds,
        selectedTeamId,
      )
    }

    if (
      currentView === CurrentView.MEMBER_TASKS &&
      statisticsData.memberMetrics
    ) {
      return getMembersDataset(
        statisticsData.memberMetrics,
        selectedItemsIds,
        selectedMemberId,
      )
    }

    return results
  }, [
    statisticsData,
    currentView,
    selectedItemsIds,
    selectedCategoriesIds,
    selectedTeamId,
    selectedMemberId,
  ])

  useEffect((): void => {
    if (startDate && endDate) {
      const start = dayjs(startDate)
      const end = dayjs(endDate)

      if (chartType === ChartType.DAY) {
        const resultsMap = new Map<string, DataSet>()

        rawDataset?.forEach((data) => {
          const date = dayjs(data.date)
          const key = data.date

          if (date.isBetween(start, end, 'day', '[]')) {
            resultsMap.set(key, {
              date: data.date,
              casesCreated:
                Number(resultsMap.get(key)?.casesCreated ?? 0) +
                Number(data.casesCreated ?? 0),
              casesResolved:
                Number(resultsMap.get(key)?.casesResolved ?? 0) +
                Number(data.casesResolved ?? 0),
              tasksAssigned:
                Number(resultsMap.get(key)?.tasksAssigned ?? 0) +
                Number(data.tasksAssigned ?? 0),
              tasksResolved:
                Number(resultsMap.get(key)?.tasksResolved ?? 0) +
                Number(data.tasksResolved ?? 0),
            })
          }
        })

        let current = start
        const results: DataSet[] = []

        while (current.isBefore(end, 'day') || current.isSame(end, 'day')) {
          const key = current.format('YYYY-MM-DD')

          results.push({
            date: key,
            casesCreated: resultsMap.get(key)?.casesCreated ?? 0,
            casesResolved: resultsMap.get(key)?.casesResolved ?? 0,
            tasksAssigned: resultsMap.get(key)?.tasksAssigned ?? 0,
            tasksResolved: resultsMap.get(key)?.tasksResolved ?? 0,
          })

          current = current.add(1, 'day')
        }

        setDataset(results)
      } else if (chartType === ChartType.WEEK) {
        const resultsMap = new Map<string, DataSet>()

        rawDataset?.forEach((data) => {
          const date = dayjs(data.date)
          const week = date.week().toString()
          const year = date.year().toString()
          const key = `${year}-${week}`

          if (date.isBetween(start, end, 'day', '[]')) {
            resultsMap.set(key, {
              date: data.date,
              casesCreated:
                Number(resultsMap.get(key)?.casesCreated ?? 0) +
                Number(data.casesCreated ?? 0),
              casesResolved:
                Number(resultsMap.get(key)?.casesResolved ?? 0) +
                Number(data.casesResolved ?? 0),
              tasksAssigned:
                Number(resultsMap.get(key)?.tasksAssigned ?? 0) +
                Number(data.tasksAssigned ?? 0),
              tasksResolved:
                Number(resultsMap.get(key)?.tasksResolved ?? 0) +
                Number(data.tasksResolved ?? 0),
            })
          }
        })

        let current = start.startOf('week')
        const results: DataSet[] = []

        while (current.isBefore(end, 'week') || current.isSame(end, 'week')) {
          const week = current.week()
          const year = current.year()
          const key = `${year}-${week}`

          results.push({
            date: current.format('YYYY-MM-DD'),
            casesCreated: resultsMap.get(key)?.casesCreated ?? 0,
            casesResolved: resultsMap.get(key)?.casesResolved ?? 0,
            tasksAssigned: resultsMap.get(key)?.tasksAssigned ?? 0,
            tasksResolved: resultsMap.get(key)?.tasksResolved ?? 0,
          })

          current = current.add(1, 'week')
        }

        setDataset(results)
      } else if (chartType === ChartType.MONTH) {
        const resultsMap = new Map<string, DataSet>()

        rawDataset?.forEach((data) => {
          const date = dayjs(data.date)
          const month = date.month().toString()
          const year = date.year().toString()
          const key = `${year}-${month}`

          if (date.isBetween(start, end, 'day', '[]')) {
            resultsMap.set(key, {
              date: data.date,
              casesCreated:
                Number(resultsMap.get(key)?.casesCreated ?? 0) +
                Number(data.casesCreated ?? 0),
              casesResolved:
                Number(resultsMap.get(key)?.casesResolved ?? 0) +
                Number(data.casesResolved ?? 0),
              tasksAssigned:
                Number(resultsMap.get(key)?.tasksAssigned ?? 0) +
                Number(data.tasksAssigned ?? 0),
              tasksResolved:
                Number(resultsMap.get(key)?.tasksResolved ?? 0) +
                Number(data.tasksResolved ?? 0),
            })
          }
        })

        let current = start.startOf('month')
        const results: DataSet[] = []

        while (current.isBefore(end, 'month') || current.isSame(end, 'month')) {
          const month = current.month()
          const year = current.year()
          const key = `${year}-${month}`

          results.push({
            date: current.format('YYYY-MM-DD'),
            casesCreated: resultsMap.get(key)?.casesCreated ?? 0,
            casesResolved: resultsMap.get(key)?.casesResolved ?? 0,
            tasksAssigned: resultsMap.get(key)?.tasksAssigned ?? 0,
            tasksResolved: resultsMap.get(key)?.tasksResolved ?? 0,
          })

          current = current.add(1, 'month')
        }

        setDataset(results)
      }
    }
  }, [rawDataset, currentView, chartType, startDate, endDate])

  const handleDateChange = (value: [Dayjs, Dayjs | null]): void => {
    setStartDate(value[0].format('YYYY-MM-DD'))
    setEndDate(value[1] ? value[1].format('YYYY-MM-DD') : null)
  }

  const handlePortalItemChange = (event: SelectChangeEvent<string[]>): void => {
    const {
      target: { value },
    } = event

    if (Array.isArray(value)) {
      const results = value.includes('') ? [] : value
      setSelectedItemsIds(results)
    }
  }

  const handleCategoryChange = (categoryIds: string[]): void => {
    setSelectedCategoriesIds(categoryIds)
  }

  const handleTeamChange = (event: SelectChangeEvent): void => {
    setSelectedTeamId(event.target.value)
  }

  const handleMemberChange = (event: SelectChangeEvent): void => {
    setSelectedMemberId(event.target.value)
  }

  const columns = useMemo((): GridColDef[] => {
    const columnsData: GridColDef[] = [
      {
        field: 'date',
        headerName: formatMessage({
          id:
            chartType === ChartType.DAY
              ? 'statistics.chart_type.day'
              : chartType === ChartType.WEEK
              ? 'statistics.chart_type.week'
              : 'statistics.chart_type.month',
        }),
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
        renderCell: (params) => {
          if (chartType === ChartType.DAY) {
            return dayjs(params.value).format(dateFormat)
          } else if (chartType === ChartType.WEEK) {
            return dayjs(params.value).week()
          }

          const newDate = new Date(params.value)

          return newDate.toLocaleString(navigator.language, {
            month: 'short',
            year: 'numeric',
          })
        },
      },
    ]

    if (currentView === CurrentView.CASES) {
      columnsData.push(
        {
          field: 'casesCreated',
          headerName: formatMessage({
            id: 'statistics.chart.label.cases_created',
          }),
          flex: 1,
          disableColumnMenu: true,
          filterable: false,
        },
        {
          field: 'casesResolved',
          headerName: formatMessage({
            id: 'statistics.chart.label.cases_resolved',
          }),
          flex: 1,
          disableColumnMenu: true,
          filterable: false,
        },
      )
    } else {
      columnsData.push(
        {
          field: 'tasksAssigned',
          headerName: formatMessage({
            id: 'statistics.chart.label.tasks_assigned',
          }),
          flex: 1,
          sortable: false,
        },
        {
          field: 'tasksResolved',
          headerName: formatMessage({
            id: 'statistics.chart.label.tasks_resolved',
          }),
          flex: 1,
          sortable: false,
        },
      )
    }

    return columnsData
  }, [currentView, chartType, dateFormat])

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>{formatMessage({ id: 'statistics.header' })} </MainHeader>
        </Box>
      </Stack>
      {currentView !== null && (
        <>
          <ViewTabsWrapper direction="row" padding={1} spacing={1}>
            <Stack direction="row" flex={1} spacing={1}>
              {statisticsData?.categoryCaseMetrics &&
                statisticsData?.categoryCaseMetrics.length > 0 && (
                  <Button
                    size="small"
                    variant={
                      currentView === CurrentView.CASES ? 'contained' : 'text'
                    }
                    onClick={() => {
                      setCurrentView(CurrentView.CASES)
                    }}
                  >
                    {formatMessage({ id: 'statistics.view.cases' })}
                  </Button>
                )}

              {statisticsData?.teamMetrics &&
                statisticsData?.teamMetrics.length > 0 && (
                  <Button
                    size="small"
                    variant={
                      currentView === CurrentView.TEAM_TASKS
                        ? 'contained'
                        : 'text'
                    }
                    onClick={() => {
                      setCurrentView(CurrentView.TEAM_TASKS)
                    }}
                  >
                    {formatMessage({ id: 'statistics.view.tasks_per_team' })}
                  </Button>
                )}

              {statisticsData?.memberMetrics &&
                statisticsData?.memberMetrics.length > 0 && (
                  <Button
                    size="small"
                    variant={
                      currentView === CurrentView.MEMBER_TASKS
                        ? 'contained'
                        : 'text'
                    }
                    onClick={() => {
                      setCurrentView(CurrentView.MEMBER_TASKS)
                    }}
                  >
                    {formatMessage({ id: 'statistics.view.tasks_per_member' })}
                  </Button>
                )}
            </Stack>
            <DateRangeInput
              defaultDateRangeShortCut={DateRangeShortCut.LAST_30_DAYS}
              label={formatMessage({ id: 'statistics.label.select_duration' })}
              disableFuture={true}
              onChange={handleDateChange}
              value={[startDate, endDate]}
            />
          </ViewTabsWrapper>
          <Stack flexGrow={1} overflow="auto" spacing={2} padding={0.3}>
            {isLoading && <CircularProgress />}

            <Stack spacing={2}>
              <Stack direction="row">
                <Stack direction="row" flex={1} spacing={4}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <SubHeader>
                      {formatMessage({
                        id:
                          currentView === CurrentView.CASES
                            ? 'statistics.view.cases'
                            : currentView === CurrentView.TEAM_TASKS
                            ? 'statistics.view.tasks_per_team'
                            : 'statistics.view.tasks_per_member',
                      })}
                    </SubHeader>

                    <Tooltip
                      title={formatMessage({
                        id:
                          currentView === CurrentView.CASES
                            ? 'statistics.info.cases'
                            : currentView === CurrentView.TEAM_TASKS
                            ? 'statistics.info.tasks_per_team'
                            : 'statistics.info.tasks_per_member',
                      })}
                    >
                      <InfoIcon fontSize="small" />
                    </Tooltip>
                  </Stack>

                  {currentView === CurrentView.CASES && (
                    <FormControl variant="standard">
                      <CategorySelect
                        label={formatMessage({
                          id: 'case_filter.label.category',
                        })}
                        value={selectedCategoriesIds}
                        onChange={handleCategoryChange}
                        categories={sortCategories<CategoryBasic>(
                          statisticsData?.categoryCaseMetrics ?? [],
                        )}
                        renderValue={
                          selectedCategoriesIds.length > 0
                            ? formatMessage(
                                { id: 'case_filter.option.n_selected' },
                                { count: selectedCategoriesIds.length },
                              )
                            : formatMessage({
                                id: 'case_filter.category.all_category',
                              })
                        }
                      />
                    </FormControl>
                  )}

                  {statisticsData?.teamMetrics &&
                    statisticsData.teamMetrics.length > 1 &&
                    currentView === CurrentView.TEAM_TASKS && (
                      <FormControl variant="standard">
                        <InputLabel id="statistics-team-select-label" shrink>
                          {formatMessage({
                            id: 'statistics.label.select_team',
                          })}
                        </InputLabel>
                        <Select
                          labelId="statistics-team-select-label"
                          size="small"
                          value={selectedTeamId}
                          defaultValue="-1"
                          onChange={handleTeamChange}
                        >
                          <MenuItem value="-1">
                            {formatMessage({
                              id: 'statistics.select_team.all_team',
                            })}
                          </MenuItem>

                          {statisticsData.teamMetrics.map((teamMetric) => (
                            <MenuItem
                              key={teamMetric.team.id}
                              value={teamMetric.team.id}
                            >
                              {teamMetric.team.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                  {statisticsData?.memberMetrics &&
                    statisticsData.memberMetrics.length > 1 &&
                    currentView === CurrentView.MEMBER_TASKS && (
                      <FormControl variant="standard">
                        <InputLabel id="statistics-member-select-label" shrink>
                          {formatMessage({
                            id: 'statistics.label.select_member',
                          })}
                        </InputLabel>
                        <Select
                          labelId="statistics-member-select-label"
                          size="small"
                          value={selectedMemberId}
                          defaultValue="-1"
                          onChange={handleMemberChange}
                        >
                          <MenuItem value="-1">
                            {formatMessage({
                              id: 'statistics.select_member.all_member',
                            })}
                          </MenuItem>

                          {statisticsData.memberMetrics.map((memberMetric) => (
                            <MenuItem
                              key={memberMetric.member.id}
                              value={memberMetric.member.id}
                            >
                              {memberMetric.member.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                  {statisticsData && statisticsData.items.length > 1 && (
                    <FormControl variant="standard">
                      <InputLabel id="statistics-item-select-label" shrink>
                        {formatMessage({
                          id: 'case_filter.label.portal_item',
                        })}
                      </InputLabel>
                      <Select
                        labelId="statistics-item-select-label"
                        multiple
                        size="small"
                        value={selectedItemsIds}
                        displayEmpty
                        onChange={handlePortalItemChange}
                        input={<Input />}
                        renderValue={(selected) =>
                          selected.length > 0
                            ? formatMessage(
                                { id: 'case_filter.option.n_selected' },
                                { count: selected.length },
                              )
                            : formatMessage({
                                id: 'case_filter.portal_item.all_item',
                              })
                        }
                      >
                        <MenuItem value="">
                          <Checkbox checked={selectedItemsIds.length === 0} />
                          <Typography marginLeft={1}>
                            {formatMessage({
                              id: 'case_filter.portal_item.all_item',
                            })}
                          </Typography>
                        </MenuItem>
                        {statisticsData.items.map((portalItem) => (
                          <MenuItem key={portalItem.id} value={portalItem.id}>
                            <Checkbox
                              checked={selectedItemsIds.includes(portalItem.id)}
                            />
                            <Typography marginLeft={1}>
                              {getLocalizedContent(portalItem.names)}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Stack>

                <ToggleButtonGroup
                  aria-label={formatMessage({
                    id: 'statistics.label.chart_type',
                  })}
                  color="primary"
                  size="small"
                  value={chartType}
                  exclusive
                  onChange={(event, value) => {
                    setChartType(value)
                  }}
                >
                  <ToggleButton value={ChartType.DAY}>
                    {formatMessage({
                      id: 'statistics.chart_type.day',
                    })}
                  </ToggleButton>
                  <ToggleButton value={ChartType.WEEK}>
                    {formatMessage({
                      id: 'statistics.chart_type.week',
                    })}
                  </ToggleButton>
                  <ToggleButton value={ChartType.MONTH}>
                    {formatMessage({
                      id: 'statistics.chart_type.month',
                    })}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>

              <LineChart
                dataset={dataset}
                xAxis={[
                  {
                    id: 'Date',
                    dataKey: 'date',
                    valueFormatter: (date): string => {
                      if (chartType === ChartType.DAY) {
                        return dayjs(date).format(dateFormat)
                      } else if (chartType === ChartType.WEEK) {
                        return formatMessage(
                          { id: 'statistics.chart.axis.week' },
                          { week: dayjs(date).week() },
                        )
                      }

                      const newDate = new Date(date)

                      return newDate.toLocaleString(navigator.language, {
                        month: 'short',
                        year: 'numeric',
                      })
                    },
                    scaleType: 'band',
                  },
                ]}
                series={chartSeries}
                height={400}
              />

              <Box width="100%" height={400} overflow="visible">
                <DataGrid
                  apiRef={dataTableRef}
                  rows={dataset.map((data) => ({
                    ...data,
                    id: nanoid(6),
                  }))}
                  rowHeight={40}
                  columns={columns}
                  hideFooter
                />
              </Box>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  )
}

export default StatisticsPage
